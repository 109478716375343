import { Card, List, Text } from "@cedcommerce-integration/ounce";
import { ExternalLink } from "@cedcommerce-integration/ounce/dist/icons";
import React from "react";

const ImportantLinks = () => {
  return (
    <Card
      cardType="bordered"
      spacing="loose"
      title="Important Links"
      customClass="header-card"
    >
      <List type="disc">
        <Text type="T-7" textcolor="subdued">
          {" "}
          View Marketplace
          <Text as="span">
            <ExternalLink
              customClass="inte-svg--inline-flex"
              size={16}
              color="#2E90FA"
            />
          </Text>
        </Text>
        <Text type="T-7" textcolor="subdued">
          {" "}
          Visit your shop (My Shop)
          <Text as="span">
            <ExternalLink
              customClass="inte-svg--inline-flex"
              size={16}
              color="#2E90FA"
            />
          </Text>
        </Text>
      </List>
    </Card>
  );
};

export default ImportantLinks;
