import { Text } from "@cedcommerce-integration/ounce";
import React from "react";

const CustomTags = ({ text, value }: any) => {
  return (
    <div className="inte-custom__tag">
      <Text type="T-8" fontweight="bold" customClass="inte-custom__tagText">
        {text}
      </Text>
      <Text
        type="T-8"
        textcolor="secondary"
        customClass="inte-custom__tagValue"
      >
        {value}
      </Text>
    </div>
  );
};
export default CustomTags;
