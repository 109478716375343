import {
  Badge,
  Button,
  Card,
  FlexLayout,
  Seprator,
  Text,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import { Check } from "@cedcommerce-integration/ounce/dist/icons";
import { useNavigate } from "react-router";

function SelectedPlan() {
  const navigate = useNavigate();
  const {width}=useWindowResize()
  return (
    <Card spacing="loose" cardType="bordered" onClick={()=>{}}>
      <FlexLayout spacing="mediumLoose" direction="vertical">
        <FlexLayout halign="fill" valign="center">
          <FlexLayout>
            <Text type="T-7" fontweight="bold">
              Selected Plan :
            </Text>
            <Badge children="Free Plan" type="success" />
          </FlexLayout>
         {width>786 &&<Button content="Change Plan" type="outlined" />}
        </FlexLayout>
        <FlexLayout desktopWidth="50">
          <FlexLayout spacing="mediumTight" valign="center">
            <Check size={20} color="var(--inte-GR200)" />
            <Text type="T-7">Manage up to 12 orders per month.</Text>
          </FlexLayout>
          <FlexLayout spacing="mediumTight" valign="center">
            <Check size={20} color="var(--inte-GR200)" />
            <Text type="T-7">Manage up to 12 orders per month.</Text>
          </FlexLayout>
          <FlexLayout spacing="mediumTight" valign="center">
            <Check  size={20} color="var(--inte-GR200)" />
            <Text type="T-7">Manage up to 12 orders per month.</Text>
          </FlexLayout>
          <FlexLayout spacing="mediumTight" valign="center">
            <Check size={20} color="var(--inte-GR200)" />
            <Text type="T-7">Manage up to 12 orders per month.</Text>
          </FlexLayout>
        </FlexLayout>
        <Seprator />
        <FlexLayout halign="end" halignMob="center" spacing="tight">
        {width<786 &&<Button content="Change Plan" type="outlined" />}
          <Button content="Continue" onClick={() => navigate("/dashboard")} />
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
}
export default SelectedPlan;
