import {
  FlexChild,
  FlexLayout,
} from "@cedcommerce-integration/ounce";
import Header from "./Header/Header";
import "./Dashboard.css";
import AttentionRequired from "./AttentionRequired/AttentionRequired";
import Status from "./Status/Status";
import DetailAnalysis from "./DetailAnalysis/DetailAnalysis";
import TopSellingPro from "./TopSellingPro/TopSellingPro";
import Support from "./Support/Support";
import ConnectedChannel from "./ConnectedChannel/ConnectedChannel";
import PricingInfo from "./PricingInfo/PricingInfo";
import RecentActivity from "./RecentActivity/RecentActivity";
import ImportantLinks from "./ImportantLinks/ImportantLinks";
import AnalyticsChart from "./Analytics/Analytics";

export default function Dashboard() {
  return (
    <>
        <Header />
        <FlexLayout direction="horizontal" spacing="mediumLoose">
          <FlexChild tabWidth="66" desktopWidth="66" mobileWidth="100">
            <FlexLayout
              direction="vertical"
              spacing="mediumLoose"
              wrap="noWrap"
            >
              <AttentionRequired />
              <AnalyticsChart />
              <Status />
              <FlexLayout
                desktopWidth="50"
                tabWidth="100"
                mobileWidth="100"
                spacing="mediumLoose"
              >
                <DetailAnalysis />
                <TopSellingPro />
              </FlexLayout>
              <Support />
            </FlexLayout>
          </FlexChild>
          <FlexChild tabWidth="33" desktopWidth="33" mobileWidth="100">
            <FlexLayout
              spacing="mediumLoose"
              childWidth="fullWidth"
              direction="vertical"
            >
              <ConnectedChannel />
              <PricingInfo />
              <RecentActivity />
              <ImportantLinks />
            </FlexLayout>
          </FlexChild>
        </FlexLayout>
    </>
  );
}
