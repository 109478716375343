import {
  Accordion,
  Alert,
  Button,
  Card,
  Checkbox,
  FlexChild,
  FlexLayout,
  List,
  Modal,
  Seprator,
  Text,
  TextField,
  TextLink,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import {
  Download,
  FileText,
  Play,
  Hint,
} from "@cedcommerce-integration/ounce/dist/icons";
import { useNavigate } from "react-router";
import { useState } from "react";
import "../Onboarding/Onboard.css";

interface StepI {
  onStepChange: () => void;
}
function ConnectWalmart({ onStepChange }: StepI) {
  const { width } = useWindowResize();
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setChecked] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Card
        cardType="bordered"
        title="Connect Walmart Account"
        subTitle="Connect your Walmart Seller account to effortlessly manage products and orders through the app."
        action={
          width < 768 ? (
            <Button
              onClick={() => setOpen(!open)}
              type="outlined"
              icon={<Play size={20} />}
            />
          ) : (
            <Button
              type="outlined"
              content="View Guide"
              icon={<Play />}
              onClick={() => setOpen(!open)}
            />
          )
        }
      >
        <Accordion
          icon={<Hint size={20} />}
          isActive={active}
          onClick={() => setActive(!active)}
          title="How to get Walmart API keys?"
        >
          <FlexLayout spacing="loose" direction="vertical">
            <List type="decimal">
              <FlexLayout>
                <Text type="T-7" textcolor="secondary">
                  Login to the{" "}
                </Text>{" "}
                <TextLink label="Walmart Developer Portal" />
              </FlexLayout>
              <FlexLayout>
                <Text type="T-7" textcolor="secondary">
                  Click on
                </Text>{" "}
                <Text type="T-7" fontweight="bold" children="My Account" />
                <Text type="T-7" children="and select login type Marketplace" />
              </FlexLayout>
              <FlexLayout>
                <Text type="T-7" textcolor="secondary">
                  {" "}
                  Click on{" "}
                </Text>{" "}
                <Text
                  type="T-7"
                  fontweight="bold"
                  children="Add New Key For A Solution Provider."
                />
              </FlexLayout>
              <FlexLayout>
                <Text type="T-7" textcolor="secondary">
                  Select the{" "}
                </Text>{" "}
                <Text
                  fontweight="bold"
                  type="T-7"
                  children="Solution Provider for the New Delegate Access Key"
                />
              </FlexLayout>
            </List>
            <Seprator />
           
              <Alert
                title={
                  <FlexLayout wrapMob="noWrap" valignMob="center" spacing="extraTight" valign="center" halign="fill">
                   <FlexChild desktopWidth="80" mobileWidth="80">
                  <FlexLayout>
                  <Text type="T-7">
                      👉 You can also find the detailed information on
                    </Text>
                    <TextLink label="Userguide" />
                  </FlexLayout>
                   </FlexChild>
                   
                  <FlexChild desktopWidth="20" tabWidth="20">
                  {width > 768 ? (
                      <Button
                        type="outlined"
                        icon={<FileText />}
                        content="User Guide"
                      />
                    ) : (
                      <Button type="outlined" icon={<FileText />} />
                    )}
                  </FlexChild>
                  </FlexLayout>
                }
                type="info"
              />
          </FlexLayout>
        </Accordion>
        <Modal
          heading="Onboarding Guide"
          modalSize="large"
          primaryAction={{
            content: "Download Guide ",
            icon: <Download size={20} />,
          }}
          isOpen={open}
          onClose={() => setOpen(false)}
        >
          Content goes here
        </Modal>
        <FlexLayout direction="vertical" spacing="mediumLoose">
          <Seprator />
          <TextField
            label="Client ID "
            isRequired
            placeHolder="Enter Text here"
          />
          <TextField
            label="Secret Key"
            isRequired
            placeHolder="Enter Text here"
          />
          <FlexLayout
            direction="vertical"
            spacing="mediumLoose"
            directionMob="vertical-reverse"
          >
            <Checkbox
              checked={value}
              label={
                <FlexLayout valign="center">
                  <Text type="T-7" children="I agree to the" />
                  <TextLink label="Terms and Condition" />
                </FlexLayout>
              }
              onChange={() => {
                setChecked(!value);
              }}
              value="radio-value"
            />
            <Seprator />
            <FlexLayout halign="fill" valign="center">
              <FlexLayout>
                <Text type="T-7" children="Don’t have an account?" />
                <TextLink
                  label="Create new"
                  onClick={() => navigate("/register")}
                />
              </FlexLayout>
              {width > 768 && (
                <Button content="Connect" onClick={onStepChange} />
              )}
            </FlexLayout>
          </FlexLayout>
          {width < 768 && (
            <Button
              isFullWidth
              halign="center"
              content="Connect"
              onClick={onStepChange}
            />
          )}
        </FlexLayout>
      </Card>
    </>
  );
}

export default ConnectWalmart;
