import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  DataTable,
  FileUpload,
  FlexChild,
  FlexLayout,
  PageHeader,
  Radio,
  Select,
  Seprator,
  Tabs,
  Text,
  TextField,
  TextLink,
  Thumbnail,
  useWindowResize,
} from "@cedcommerce-integration/ounce";

import { useLocation, useNavigate } from "react-router-dom";
import { TabI } from "@cedcommerce-integration/ounce/dist/components/Tabs/Tabs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditProduct.css";
import {
  User,
  AlertCircle,
  Truck,
  Store,
  Repeat,
  Plus,
  Move,
  Check,
  Box,
} from "@cedcommerce-integration/ounce/dist/icons";

const EditProduct = () => {
  const { width } = useWindowResize();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string | number>("title");
  const [categoryValue, setCategoryValue] = useState("india");
  const [editValue, setEditValue] = useState("");
  const [holdAllIamges, setHoldAllIamges] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [checkImage, setCheckImage] = useState(false);
  const [wrapper, setWrapper] = useState(-1);
  const [productTitle, setProductTitle] = useState("shopifyTitle");
  const [productDescription, setProductDescription] =
    useState("shopifyDescription");
  const [value1, setValue1] = useState(["india"]);

  const [requiredAttributes1, setRequiredAttributes1] = useState(["india"]);
  const [requiredAttributes2, setRequiredAttributes2] = useState(["india"]);
  const [optionalAttributes1, setOptionalAttributes1] = useState(["Length"]);
  const [optionalAttributes2, setOptionalAttributes2] = useState(["Length"]);
  const [tableDataRow, setTableRowData] = useState([
    {
      id: 1,
      key: "1",
      name: "",
    },
  ]);

  useEffect(() => {
    let holdData: any = [];
    setDataLoading(true);
    fetch(`https://dummyjson.com/products/${location.state}`)
      .then((res) => res.json())
      .then((json) => {
        setDataLoading(false);
        setHoldAllIamges(json.images);
        json?.images?.map((imgUrl: string, index: number) => {
          return (holdData = [
            ...holdData,
            {
              id: index,
              key: `${index}`,
              image: <Thumbnail alt="Image Data Not Found" src={imgUrl} />,
              sku: <TextField placeHolder="7811545535" />,
              code: <TextField placeHolder="4611545535354353" />,
              price: <TextField value={json.price} />,
              quantity: <TextField placeHolder={`${json.stock}`} />,
              size: <TextField placeHolder="M" />,
              color: <TextField placeHolder="Red" />,
            },
          ]);
        });
        setTableRowData(holdData);
      });
  }, []);

  const tabsArr: TabI[] = [
    {
      key: "title",
      label: "Title",
      icon: <Store size={18} />,
    },
    {
      key: "description",
      label: "Description",
      icon: <Box size={20} />,
    },
    {
      key: "image",
      label: "Image & Media",
      icon: <Truck size={20} />,
    },
    {
      key: "global",
      label: "Global Attributes",
      icon: <Repeat size={20} />,
    },
    {
      key: "variants",
      label: "Variants",
      icon: <User size={16} />,
    },
  ];
  const columnData = [
    {
      dataIndex: "image",
      key: "imge",
      title: "Image",
      width: 70,
    },
    {
      dataIndex: "sku",
      key: "sku",
      title: "SKU",
      width: 200,
    },
    {
      dataIndex: "code",
      key: "code",
      title: "Code",
      width: 200,
    },
    {
      dataIndex: "price",
      key: "price",
      title: "Price",
      width: 150,
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: "Quantity",
      width: 200,
    },
    {
      dataIndex: "size",
      key: "size",
      title: "Size",
      width: 200,
    },
    {
      dataIndex: "color",
      key: "color",
      title: "Color",
      width: 200,
    },
  ];

  const renderTabsData: { [key: string]: JSX.Element } = {
    title: (
      <>
        <Card
          spacing="loose"
          cardType="bordered"
          title="Product Title"
          subTitle=" Set a suitable custom title for the product or use the same one
          from the Shopify store."
        >
          <FlexLayout spacing="loose" direction="vertical">
            <Radio
              label="Set Shopify title as TikTok Shop Title"
              checked={productTitle === "shopifyTitle"}
              onChange={() => setProductTitle("shopifyTitle")}
            />
            <Radio
              label="Set Custom TikTok Shop Title"
              checked={productTitle === "customTiktok"}
              onChange={() => setProductTitle("customTiktok")}
            />
            {productTitle === "customTiktok" && (
              <TextField
                value="Title1"
                customClass="inte-customTikTok__textField"
              />
            )}
          </FlexLayout>
        </Card>

        <Card
          spacing="loose"
          cardType="bordered"
          title="Description"
          subTitle="Mention a detailed yet precise Product Description embedded with
                ‘Keywords’ that define the Product appropriately here. To know
                more, check out this guide on"
        >
          <FlexLayout spacing="loose" direction="vertical">
            <Radio
              label="Set Shopify Description as TikTok Shop Description"
              checked={productDescription === "shopifyDescription"}
              onChange={() => setProductDescription("shopifyDescription")}
            />
            <Radio
              label="Set Custom TikTok Shop Description"
              checked={productDescription === "customTikTok"}
              onChange={() => setProductDescription("customTikTok")}
            />
            {productDescription === "customTikTok" && (
              <ReactQuill value={editValue} onChange={setEditValue} />
            )}
          </FlexLayout>
        </Card>

        {productDescription === "customTikTok" && (
          <Card
            spacing="loose"
            cardType="bordered"
            title="Product Category"
            subTitle="Choose the category of TikTok Shop that best defines your
          products."
          >
            <FlexLayout spacing="mediumLoose" direction="vertical">
              <Alert
                icon={<AlertCircle />}
                hasDestroy
                type="info"
                title="Tiktok category is not present since no template is applied to this product."
              />
              <FlexLayout spacing="loose" direction="vertical">
                <Radio
                  label="Set Category from Product Template"
                  isDisable
                  onChange={() => {}}
                />
                <Radio
                  label="Set new product category"
                  checked={productDescription === "customTikTok"}
                  onChange={() => setProductDescription("customTikTok")}
                />
                {productDescription === "customTikTok" && (
                  <Select
                    options={[
                      {
                        label: "India",
                        value: "india",
                      },
                      {
                        label: "America",
                        value: "America",
                      },
                    ]}
                    value={categoryValue}
                    isClearable
                    placeholder={"Select"}
                    onChange={(e) => setCategoryValue(e)}
                  />
                )}
              </FlexLayout>
            </FlexLayout>
          </Card>
        )}

        <Card
          spacing="loose"
          cardType="bordered"
          title="Image & Media"
          subTitle=" Add images by upload files or by image URLs"
        >
          <FlexLayout spacing="mediumLoose" direction="vertical">
            <FlexLayout spacing="loose" direction="vertical">
              <Card spacing="loose" cardType="bordered">
                <FlexLayout spacing="mediumLoose" direction="vertical">
                  <Text type="T-7" textcolor="secondary">
                    Current Images
                  </Text>
                  <FlexLayout spacing="loose" direction="vertical">
                    <FlexLayout spacing="loose">
                      {holdAllIamges?.map((url, index) => {
                        return (
                          <div
                            className="inte-editProduct__thumbnail"
                            onMouseEnter={() => setWrapper(index)}
                            onMouseLeave={() => setWrapper(-1)}
                            key={index}
                          >
                            {wrapper === index && (
                              <>
                                <div className="inte-editProduct__text">
                                  <Checkbox
                                    checked={checkImage}
                                    onChange={() => setCheckImage(!checkImage)}
                                  />
                                  <Move size="20" color="#ffff" />
                                </div>
                                <div className="inte-editProduct__overLay" />
                              </>
                            )}
                            <Thumbnail src={url} />
                          </div>
                        );
                      })}
                    </FlexLayout>

                    <FlexLayout halign="center">
                      <Button
                        content="Show more"
                        iconAlign="right"
                        type="outlined"
                        icon={<Plus />}
                        size="thin"
                      />
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </Card>
            </FlexLayout>

            <FileUpload
              accept={["png", "jpg", "jpeg"]}
              customClass=""
              innerLabel={
                <FlexLayout>
                  <Button type="textButton" content="Click to upload" />
                  <Text type="T-8" textcolor="secondary">
                    or drag and drop
                  </Text>
                </FlexLayout>
              }
              isDragable
              maxCount={5}
              maxSizeAllowed={500000}
              onChange={(e) => {}}
              onRemove={function noRefCheck() {}}
              type="secondary"
            />
            <Seprator text="or" />
            <FlexLayout spacing="loose">
              <FlexChild childWidth="fullWidth">
                <TextField placeHolder="Enter image url" />
              </FlexChild>
              <FlexChild>
                <Button content="Add image" />
              </FlexChild>
            </FlexLayout>
          </FlexLayout>
        </Card>

        <Card
          spacing="loose"
          cardType="bordered"
          title="Product Category"
          subTitle=" Choose the category of TikTok Shop that best defines your products."
        >
          <FlexLayout spacing="tight" direction="vertical">
            <Radio
              id="id"
              label="Set Shopify title as TikTok Shop Title"
              name="Label"
              onChange={() => {}}
              description={
                <Badge
                  customClass="inte-badge-radio"
                  variant="accent"
                  type="secondary"
                >
                  Beauty & Personal CareMakeup &gt; Body &gt; Makeup
                </Badge>
              }
            />

            <Radio
              id="02"
              label="Set Custom TikTok Shop Title"
              name="Label"
              onChange={() => {}}
            />
          </FlexLayout>
        </Card>

        <Card spacing="loose" cardType="bordered">
          <FlexLayout spacing="mediumLoose" direction="vertical">
            <FlexLayout spacing="extraTight" direction="vertical">
              <Text type="T-7" fontweight="bold">
                Attributes Mapping
              </Text>
              <Text type="T-7" textcolor="secondary">
                Description
              </Text>
            </FlexLayout>
            <Accordion isActive onClick={() => {}} title="Required Attributes">
              <FlexLayout direction="vertical" spacing="tight">
                <FlexLayout desktopWidth="50" mobileWidth="100">
                  <FlexChild>
                    <FlexLayout direction="vertical" spacing="mediumTight">
                      <Text type="T-7" fontweight="bold">
                        TikTok Shop Attribute
                      </Text>
                      <FlexLayout spacing="tight">
                        {width > 768 ? (
                          <Badge
                            type="success"
                            size="small"
                            icon={<Check size={16} />}
                          />
                        ) : (
                          <Check size={20} color="#1B875A" />
                        )}

                        <Text type="T-7">Package Length *</Text>
                      </FlexLayout>
                    </FlexLayout>
                  </FlexChild>
                  <FlexChild>
                    <FlexLayout direction="vertical" spacing="tight">
                      <Text type="T-7" fontweight="bold">
                        BigCommerce Attribute
                      </Text>

                      <Select
                        label="sdgsdfg"
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },
                        ]}
                        value={value1}
                        placeholder={"Select"}
                        onChange={(e) => setValue1(e)}
                      />
                      <Select
                        isMultiSelect
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },

                          {
                            label: "Length",
                            value: "Length",
                          },
                        ]}
                        value={requiredAttributes1}
                        placeholder={"Select"}
                        onChange={(e) => setRequiredAttributes1(e)}
                      />
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
                <Seprator />
                <FlexLayout desktopWidth="50" mobileWidth="100">
                  <FlexChild>
                    <FlexLayout spacing="tight">
                      <Badge
                        size="small"
                        icon={<Check size={16} />}
                        type="success"
                      />
                      <Text type="T-7">Package Length *</Text>
                    </FlexLayout>
                  </FlexChild>
                  <FlexChild>
                    <FlexLayout direction="vertical" spacing="tight">
                      <>{width < 786 && <Seprator type="dashed" />}</>

                      <Select
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },
                        ]}
                        value={value1}
                        placeholder="Select"
                        onChange={(e) => setValue1(e)}
                      />
                      <Select
                        isMultiSelect
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },

                          {
                            label: "Length",
                            value: "Length",
                          },
                        ]}
                        value={requiredAttributes2}
                        placeholder={"Select"}
                        onChange={(e) => setRequiredAttributes2(e)}
                      />
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
              </FlexLayout>
            </Accordion>

            <Accordion isActive onClick={() => {}} title="Optional Attributes">
              <FlexLayout direction="vertical" spacing="tight">
                <FlexLayout desktopWidth="50" mobileWidth="100">
                  <FlexChild>
                    <FlexLayout direction="vertical" spacing="mediumTight">
                      <Text type="T-7" fontweight="bold">
                        TikTok Shop Attribute
                      </Text>
                      <FlexLayout spacing="tight">
                        {width > 768 ? (
                          <Badge
                            type="success"
                            size="small"
                            icon={<Check size={16} />}
                          />
                        ) : (
                          <Check size={20} color="#1B875A" />
                        )}

                        <Text type="T-7">Display</Text>
                      </FlexLayout>
                    </FlexLayout>
                  </FlexChild>
                  <FlexChild>
                    <FlexLayout direction="vertical" spacing="tight">
                      <Text type="T-7" fontweight="bold">
                        BigCommerce Attribute
                      </Text>

                      <Select
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },
                        ]}
                        value={value1}
                        placeholder={"Select"}
                        onChange={(e) => setValue1(e)}
                      />
                      <Select
                        isMultiSelect
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },

                          {
                            label: "Length",
                            value: "Length",
                          },
                        ]}
                        value={optionalAttributes1}
                        placeholder="Select"
                        onChange={(e) => setOptionalAttributes1(e)}
                      />
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
                <Seprator />
                <FlexLayout desktopWidth="50" mobileWidth="100">
                  <FlexChild>
                    <FlexLayout spacing="tight">
                      <Badge
                        size="small"
                        icon={<Check size={16} />}
                        type="success"
                      />
                      <Text type="T-7">Interface Type</Text>
                    </FlexLayout>
                  </FlexChild>
                  <FlexChild>
                    <FlexLayout direction="vertical" spacing="tight">
                      <>{width < 786 && <Seprator type="dashed" />}</>

                      <Select
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },
                        ]}
                        value={value1}
                        placeholder="Select"
                        onChange={(e) => setValue1(e)}
                      />
                      <Select
                        isMultiSelect
                        options={[
                          {
                            label: "India",
                            value: "india",
                          },
                          {
                            label: "America",
                            value: "America",
                          },

                          {
                            label: "Length",
                            value: "Length",
                          },
                        ]}
                        value={optionalAttributes2}
                        placeholder="Select"
                        onChange={(e) => setOptionalAttributes2(e)}
                      />
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
              </FlexLayout>
            </Accordion>
          </FlexLayout>
        </Card>

        <Card
          cardType="bordered"
          spacing="loose"
          title="Manage, edit, and view Variants along with the related themes of
                the product."
          subTitle="Variations"
        >
          <DataTable
            dataSource={tableDataRow}
            isLoading={dataLoading}
            columns={columnData}
            // scrollX={200}
          />
        </Card>
      </>
    ),
    description: (
      <Card spacing="loose" cardType="bordered">
        <FlexLayout halign="center">description</FlexLayout>
      </Card>
    ),
    image: (
      <Card spacing="loose" cardType="bordered">
        <FlexLayout halign="center">image</FlexLayout>
      </Card>
    ),
    global: (
      <Card spacing="loose" cardType="bordered">
        fsdf
      </Card>
    ),
    variants: (
      <Card spacing="loose" cardType="bordered">
        <FlexLayout halign="center">account</FlexLayout>
      </Card>
    ),
  };

  return (
    <>
      <PageHeader
        hasReverseNavigation
        onClick={() => navigate("/product/productlist")}
        title="Product Title"
        description={<TextLink label="Link here" />}
        titleBadge={{
          children: "Not Listed",
          size: "small",
          type: "secondary",
          variant: "accent",
        }}
        connectRight={
          <Button
            type="primary"
            halign="center"
            children="Save"
            isDisable={true}
          />
        }
      />
      <Tabs
        direction="vertical"
        tabs={tabsArr}
        spacing="mediumLoose"
        value={selectedTab}
        onChange={(newTab: any) => setSelectedTab(newTab)}
        customClass="inte-tabs__customClass"
      >
        {renderTabsData[selectedTab as string]}
      </Tabs>
    </>
  );
};

export default EditProduct;
