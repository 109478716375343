import React from 'react'
import {Card, FlexChild, FlexLayout, PageHeader, Select, Text, TextField,} from '@cedcommerce-integration/ounce'
import AssignProduct from './AssignProduct/AssignProduct'
import AttributeMapping from './AttributeMapping/AttributeMapping'
import './CreateCategoryTemplate.css'
import { useNavigate } from 'react-router-dom'

const CreateCategoryTemplate = () => {

    const navigator = useNavigate()

  return (
    <>
      <PageHeader
        title="Create Category Template"
        hasReverseNavigation
        onClick={() =>navigator(-1)}
      />
      <FlexLayout spacing='mediumLoose' direction='vertical' wrap='noWrap'>
        <Card cardType='bordered'>
          <FlexLayout childWidth='fullWidth' spacing='mediumLoose'>
            <FlexChild desktopWidth='33'>
              <FlexLayout spacing='extraTight' direction='vertical'>
                <Text as={'h3'} type="T-7" fontweight='bold'>Template Name <Text as={'span'} type='T-7' textcolor='negative' >*</Text></Text>
                <Text type='T-7'>Template name must be unique and should not exceed 80 characters.</Text>
              </FlexLayout>
            </FlexChild>
            <FlexChild desktopWidth='66'>
              <TextField placeHolder='Enter Template Name'></TextField>
            </FlexChild>
          </FlexLayout>
        </Card>
       <AssignProduct />
        <Card cardType='bordered'>
          <FlexLayout childWidth='fullWidth' spacing='mediumLoose'>
            <FlexChild desktopWidth='33'>
              <>
                <Text as={'h3'} type="T-7" fontweight='bold'>Category Name <Text as={'span'} type='T-7' textcolor='negative' >*</Text></Text>
                <Text type='T-7'>Template name must be unique and should not exceed 80 characters.</Text>
              </>
            </FlexChild>
            <FlexChild desktopWidth='66'>
              <Select
                options={[{ label: 'Category 1', value: "aa" },{ label: 'Category 2', value: "aa" },{ label: 'Category 3', value: "aa" }]}
                value={""}
                helpIcon={<Text type='T-7' fontweight='bold'>Note:</Text>}
                helpText='Based on the selected category you will further map Magento attribute with setup attributes.'
                placeholder='Select Category'
              />
            </FlexChild>
          </FlexLayout>
        </Card>
        <AttributeMapping />
      </FlexLayout>
    </>
  )
}

export default CreateCategoryTemplate