import {
  Button,
  PageHeader,
  Tabs,
} from "@cedcommerce-integration/ounce";
import { TabI } from "@cedcommerce-integration/ounce/dist/components/Tabs/Tabs";
import React, { useState } from "react";
import ProductSetting from "./ProductSetting";
import Shipping from "./Shipping";
import Account from "./Account";
import { Box, Repeat, Store, Truck, User } from "@cedcommerce-integration/ounce/dist/icons";

const Setting = () => {
  const [selectedTab, setSelectedTab] = useState<string | number>("tickTok");

  const tabsArr: TabI[] = [
    {
      key: "tickTok",
      label: "TickTock Setting",
      icon: <Store size={16} />,
    },
    {
      key: "product",
      label: "Product Setting",
      icon: <Box size={16} />,
    },
    {
      key: "shipping",
      label: "Shipping Carring Mapping",
      icon: <Truck size={16} />,
    },
    {
      key: "global",
      label: "Global Attributes",
      icon: <Repeat size={16} />,
    },
    {
      key: "account",
      label: "Account",
      icon: <User size={16} />,
    },
  ];

  const tabPages: { [key: string]: JSX.Element } = {
    tickTok: <Account />,
    product: <ProductSetting />,
    shipping: <Shipping />,
    global: <ProductSetting />,
    account: <Account />,
  };

  return (
    <>
      <PageHeader
        title={"Configuration"}
        description={"View and manage configurations for automated data-sync operations with the TikTok Shop."}
        connectRight={
          <Button
            type="primary"
            halign="center"
            children="Save Changes"
            isDisable={true}
          />
        }
      />
      <Tabs
        tabs={tabsArr}
        value={selectedTab}
        onChange={(newTabKey) => setSelectedTab(newTabKey)}
        direction="vertical"
        spacing="mediumLoose"
        customClass="inte-tabs__customClass"
      >
        {tabPages[selectedTab as string]}
      </Tabs>
    </>
  );
};

export default Setting;
