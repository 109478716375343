export const logo = (
  <svg
    width="230"
    height="49"
    viewBox="0 0 230 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9002 23.6379L38.7025 13.1202L25.2273 0L8.92455 15.8303L0 24.4767L25.0285 48.846L39.4315 34.8224L27.9002 23.6379ZM5.45634 24.5198L25.2273 5.33412L33.2461 13.1417L25.1831 20.9924L22.0242 17.9166L19.285 20.5837L22.4439 23.6594L15.5075 30.4131L18.2467 33.0802L25.1831 26.3265L33.953 34.8654L25.0064 43.5764L5.45634 24.5198Z"
      fill="white"
    />
    <path
      d="M113.572 23.7455V0H51.4314V23.3798V48.889L193.075 48.9105V23.724H113.572V23.7455ZM52.9335 1.48409H112.048V23.7455H52.9335V1.48409ZM191.573 47.4479H52.9335V25.2296H112.07V31.252V40.9093H113.837V34.5858L117.084 39.7909H117.902L121.171 34.4997L121.193 40.9093H122.938L122.916 31.252H121.392L117.526 37.6615L113.594 31.252V25.2296H191.595V47.4479H191.573Z"
      fill="white"
    />
    <path
      d="M61.8371 9.11944C62.3673 8.83982 62.9858 8.68926 63.6706 8.68926C64.7088 8.68926 65.5925 9.07642 66.3214 9.82922L67.5143 8.73228C67.0504 8.21608 66.4761 7.80742 65.8134 7.5278C65.1507 7.24819 64.3996 7.11914 63.5822 7.11914C62.5882 7.11914 61.6824 7.33423 60.8651 7.7644C60.0478 8.19457 59.4292 8.7753 58.9653 9.5281C58.5014 10.2809 58.2805 11.1412 58.2805 12.0876C58.2805 13.034 58.5014 13.8943 58.9653 14.6471C59.4292 15.3999 60.0478 16.0022 60.8651 16.4108C61.6824 16.841 62.5882 17.0561 63.5822 17.0561C64.3996 17.0561 65.1286 16.927 65.8134 16.6474C66.4982 16.3678 67.0504 15.9807 67.5143 15.443L66.3214 14.346C65.5925 15.0988 64.7309 15.486 63.6706 15.486C62.9858 15.486 62.3893 15.3354 61.8371 15.0558C61.3069 14.7762 60.8872 14.3675 60.5779 13.8513C60.2687 13.3351 60.114 12.7544 60.114 12.1091C60.114 11.4639 60.2687 10.8831 60.5779 10.3669C60.8651 9.80771 61.2848 9.39905 61.8371 9.11944Z"
      fill="white"
    />
    <path
      d="M78.7793 12.7327H83.5729V11.2486H78.7793V8.75365H84.1694V7.24805H76.9458V16.9054H84.3682V15.3998H78.7793V12.7327Z"
      fill="white"
    />
    <path
      d="M100.98 7.85029C100.163 7.44162 99.2131 7.24805 98.1527 7.24805H93.823V16.9054H98.1527C99.2131 16.9054 100.141 16.7118 100.98 16.3032C101.798 15.8945 102.438 15.3353 102.902 14.604C103.366 13.8727 103.587 13.0339 103.587 12.066C103.587 11.1196 103.366 10.2592 102.902 9.52795C102.438 8.79666 101.798 8.23744 100.98 7.85029ZM101.267 13.8082C100.98 14.3029 100.538 14.69 99.9862 14.9696C99.434 15.2492 98.7934 15.3783 98.0644 15.3783H95.6565V8.75365H98.0644C98.7934 8.75365 99.434 8.8827 99.9862 9.16231C100.538 9.44192 100.958 9.82908 101.267 10.3238C101.555 10.8185 101.709 11.3992 101.709 12.066C101.709 12.7327 101.577 13.3135 101.267 13.8082Z"
      fill="white"
    />
    <path
      d="M61.8371 33.1233C62.3673 32.8437 62.9858 32.6932 63.6706 32.6932C64.7088 32.6932 65.5925 33.0803 66.3214 33.8331L67.5143 32.7362C67.0504 32.22 66.4761 31.8113 65.8134 31.5317C65.1507 31.2521 64.3996 31.123 63.5822 31.123C62.5882 31.123 61.6824 31.3381 60.8651 31.7683C60.0478 32.1985 59.4292 32.7792 58.9653 33.532C58.5014 34.2848 58.2805 35.1451 58.2805 36.0915C58.2805 37.0379 58.5014 37.8982 58.9653 38.651C59.4292 39.4038 60.0478 40.0061 60.8651 40.4147C61.6824 40.8449 62.5882 41.06 63.5822 41.06C64.3996 41.06 65.1286 40.931 65.8134 40.6513C66.4982 40.3717 67.0504 39.9846 67.5143 39.4469L66.3214 38.3499C65.5925 39.1027 64.7309 39.4899 63.6706 39.4899C62.9858 39.4899 62.3893 39.3393 61.8371 39.0597C61.3069 38.7801 60.8872 38.3714 60.5779 37.8552C60.2687 37.339 60.114 36.7583 60.114 36.113C60.114 35.4678 60.2687 34.887 60.5779 34.3708C60.8651 33.8116 61.2848 33.403 61.8371 33.1233Z"
      fill="white"
    />
    <path
      d="M83.2421 31.7463C82.4247 31.3162 81.519 31.1011 80.5029 31.1011C79.4867 31.1011 78.581 31.3162 77.7637 31.7463C76.9463 32.1765 76.3057 32.7572 75.8418 33.5315C75.3779 34.2843 75.157 35.1447 75.157 36.0696C75.157 37.0159 75.3779 37.8548 75.8418 38.6076C76.3057 39.3604 76.9463 39.9626 77.7637 40.3928C78.581 40.8229 79.4867 41.038 80.5029 41.038C81.519 41.038 82.4247 40.8229 83.2421 40.3928C84.0594 39.9626 84.7001 39.3819 85.164 38.6291C85.6279 37.8763 85.8488 37.0159 85.8488 36.0696C85.8488 35.1232 85.6279 34.2628 85.164 33.51C84.7001 32.7787 84.0594 32.1765 83.2421 31.7463ZM83.5514 37.8117C83.2421 38.328 82.8224 38.7366 82.3143 39.0162C81.7841 39.2958 81.1877 39.4464 80.525 39.4464C79.8622 39.4464 79.2658 39.2958 78.7356 39.0162C78.2055 38.7366 77.7857 38.328 77.4986 37.8117C77.1893 37.2955 77.0347 36.7148 77.0347 36.0696C77.0347 35.4243 77.1893 34.8436 77.4986 34.3274C77.8078 33.8112 78.2055 33.4025 78.7356 33.1229C79.2658 32.8433 79.8622 32.6927 80.525 32.6927C81.1877 32.6927 81.7841 32.8433 82.3143 33.1229C82.8445 33.4025 83.2642 33.8112 83.5514 34.3274C83.8606 34.8436 84.0153 35.4243 84.0153 36.0696C83.9932 36.7148 83.8385 37.2955 83.5514 37.8117Z"
      fill="white"
    />
    <path
      d="M102.858 31.252L98.9704 37.64L95.0383 31.252H93.5361V40.9093H95.2813V34.5858L98.5507 39.8124H99.368L102.615 34.4997L102.637 40.9093H104.383L104.36 31.252H102.858Z"
      fill="white"
    />
    <path
      d="M138.025 39.4037H132.414V36.7366H137.208V35.2741H132.414V32.7576H137.826V31.252H130.581V40.9093H138.025V39.4037Z"
      fill="white"
    />
    <path
      d="M147.524 38.0917H149.755C149.932 38.0917 150.042 38.0917 150.131 38.0702L152.163 40.8878H154.151L151.876 37.726C152.561 37.4679 153.069 37.0808 153.444 36.5431C153.798 36.0269 153.996 35.4031 153.996 34.6718C153.996 33.962 153.82 33.3598 153.488 32.8436C153.157 32.3274 152.649 31.9402 152.03 31.6606C151.39 31.381 150.639 31.252 149.777 31.252H145.69V40.9093H147.524V38.0917ZM147.524 32.7576H149.667C150.462 32.7576 151.08 32.9296 151.5 33.2522C151.92 33.5749 152.119 34.0481 152.119 34.6718C152.119 35.2956 151.92 35.7688 151.5 36.0914C151.08 36.4355 150.484 36.5861 149.667 36.5861H147.524V32.7576Z"
      fill="white"
    />
    <path
      d="M164.425 40.3927C165.242 40.8229 166.148 41.038 167.142 41.038C167.959 41.038 168.688 40.9089 169.373 40.6293C170.058 40.3497 170.61 39.9626 171.074 39.4248L169.881 38.3279C169.152 39.0807 168.291 39.4679 167.23 39.4679C166.546 39.4679 165.949 39.3173 165.397 39.0377C164.867 38.7581 164.447 38.3494 164.138 37.8332C163.828 37.317 163.674 36.7363 163.674 36.091C163.674 35.4458 163.828 34.865 164.138 34.3488C164.447 33.8326 164.867 33.4239 165.397 33.1443C165.927 32.8647 166.546 32.7142 167.23 32.7142C168.269 32.7142 169.152 33.1013 169.881 33.8541L171.074 32.7572C170.61 32.241 170.036 31.8323 169.373 31.5527C168.71 31.2731 167.959 31.144 167.142 31.144C166.148 31.144 165.242 31.3591 164.425 31.7893C163.608 32.2195 162.989 32.8002 162.525 33.553C162.061 34.3058 161.84 35.1661 161.84 36.1125C161.84 37.0589 162.061 37.9192 162.525 38.672C162.989 39.3818 163.608 39.9841 164.425 40.3927Z"
      fill="white"
    />
    <path
      d="M186.183 39.4037H180.594V36.7366H185.388V35.2741H180.594V32.7576H185.984V31.252H178.738V40.9093H186.183V39.4037Z"
      fill="white"
    />
  </svg>
);
export const mobileLogo = (
  <svg
    width="40"
    height="50"
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9002 24.2151L38.7025 13.6974L25.2273 0.577148L8.92455 16.4075L0 25.0539L25.0285 49.4231L39.4315 35.3995L27.9002 24.2151ZM5.45634 25.0969L25.2273 5.91127L33.2461 13.7189L25.1831 21.5695L22.0242 18.4938L19.285 21.1608L22.4439 24.2366L15.5075 30.9903L18.2467 33.6573L25.1831 26.9036L33.953 35.4425L25.0064 44.1535L5.45634 25.0969Z"
      fill="white"
    />
  </svg>
);
export const checkIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="#F3F4F5" />
    <path
      d="M14 7L8.5 12.5L6 10"
      stroke="#8C9098"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
