import {
  Alert,
  Button,
  Card,
  FlexChild,
  FlexLayout,
  Select,
  Seprator,
  StepWizard,
  Text,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import {
  AlertCircle,
  ArrowRight,
} from "@cedcommerce-integration/ounce/dist/icons";
import "./Onboard.css";
import { Logo } from "./icons";
import { useState } from "react";
import Configuration from "./Configuration";

function StepOne() {
  const { width } = useWindowResize();
  const [value, setValue] = useState("india");
  const [step, setStep] = useState(1);
  return (
    <>
      <FlexLayout
        desktopWidth="66"
        mobileWidth="100"
        tabWidth="100"
        valign="center"
        direction="vertical"
      >
        <Card customClass="pt40" cardType="borderLess">
          <FlexLayout wrap="noWrap" spacing="loose" customClass="mb-36">
            {Logo}
            <FlexChild>
              <FlexLayout direction="vertical">
                <Text type="T-4" fontweight="bolder">
                  Welcome to TikTok Shop Integration
                </Text>
                <Text type="T-4" textcolor="secondary">
                  {" "}
                  By CedCommerce
                </Text>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
          <FlexLayout spacing="tight" desktopWidth="100" direction="vertical">
            <StepWizard
              currentStep={step}
              direction="horizontal"
              onChange={(newStep) => {
                // setStep(newStep)
              }}
              size="small"
              steps={[
                {
                  label: "Connect Account",
                  value: "Step1",
                },
                {
                  label: "Connection error",
                  value: "Step2",
                },
                {
                  label: "Link Account",
                  value: "Step3",
                },
                {
                  label: "Configuration",
                  value: "Step4",
                },
              ]}
            />

            {step === 1 && (
              <Card cardType="bordered">
                <FlexLayout spacing="mediumLoose" direction="vertical">
                  <FlexLayout spacing="mediumTight" direction="vertical">
                    <Text type="T-7" fontweight="bold">
                      Authorize Connect Account
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      Click to authorize your (App Name) Connect Account to
                      connect with the app.
                    </Text>
                  </FlexLayout>
                  <Seprator />
                  <FlexLayout
                    halign="end"
                    childWidth={width > 768 ? "none" : "fullWidth"}
                  >
                    <Button
                      isFullWidth={width > 768 ? false : true}
                      halign="center"
                      icon={<ArrowRight />}
                      content="Authorize"
                      onClick={() => {
                        setStep(2);
                      }}
                    />
                  </FlexLayout>
                </FlexLayout>
              </Card>
            )}

            {step === 2 && (
              <Card cardType="bordered">
                <FlexLayout spacing="mediumLoose" direction="vertical">
                  <FlexLayout spacing="mediumTight" direction="vertical">
                    <Text type="T-7" fontweight="bold">
                      Authorize Connect Account
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      Click to authorize your (App Name) Connect Account to
                      connect with the app.
                    </Text>
                  </FlexLayout>

                  <Alert
                    icon={<AlertCircle size="15" />}
                    description="It appears that you’re attempting to authorize with a Mirakl Connect Account that is different from the one you use to login. Unfortunatly, we can not proceed with this request. To resolve this issue, You can authorize with the same Mirakl Connect Account that you used during login or go back and login from account for which you want to do authorization."
                    title="Connection Error"
                    type="danger"
                  />

                  <Seprator />
                  <FlexLayout
                    halign="end"
                    childWidth={width > 768 ? "none" : "fullWidth"}
                  >
                    <Button
                      isFullWidth={width > 768 ? false : true}
                      halign="center"
                      icon={<ArrowRight />}
                      content="Authorize"
                      onClick={() => {
                        setStep(3);
                      }}
                    />
                  </FlexLayout>
                </FlexLayout>
              </Card>
            )}
            {step === 3 && (
              <Card cardType="bordered">
                <FlexLayout
                  spacing="mediumLoose"
                  direction="vertical"
                  mobileWidth="100"
                >
                  <FlexLayout spacing="mediumTight" direction="vertical">
                    <Text type="T-7" fontweight="bolder">
                      Authorize Connect Account
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      Click to authorize your (App Name) Connect Account to
                      connect with the app.
                    </Text>
                  </FlexLayout>
                  <Select
                    options={[
                      {
                        label: "India",
                        value: "india",
                      },
                      {
                        label: "America",
                        value: "America",
                      },
                    ]}
                    value={value}
                    placeholder={"Select"}
                    onChange={(e) => {
                      setValue(e);
                    }}
                  />
                  <Seprator />
                  <FlexLayout
                    halign="end"
                    childWidth={width > 768 ? "none" : "fullWidth"}
                  >
                    <Button
                      isFullWidth={width >= 768 ? false : true}
                      halign="center"
                      onClick={() => setStep(4)}
                      icon={<ArrowRight />}
                      content="Sign In"
                    />
                  </FlexLayout>
                </FlexLayout>
              </Card>
            )}
            {step === 4 && <Configuration />}
          </FlexLayout>
        </Card>
      </FlexLayout>
    </>
  );
}

export default StepOne;
