import { Button, PageHeader } from "@cedcommerce-integration/ounce";

import { FileText } from "@cedcommerce-integration/ounce/dist/icons";
const Header = () => {
  return (
    <PageHeader
      title={"Dashboard"}
      description={"Here’s what’s happening in your store today."}
      connectRight={
        <Button
          type="outlined"
          halign="center"
          children="Guide"
          icon={<FileText size="20" color="#1c2433" />}
        />
      }
    ></PageHeader>
  );
};

export default Header;
