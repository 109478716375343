import React, { FC, useEffect, useState } from "react";
import {
  Seprator,
  Text,
  Button,
  TextField,
  Alert,
  useToast,
} from "@cedcommerce-integration/ounce";
import {
  ArrowLeft,
  CheckCircle,
} from "@cedcommerce-integration/ounce/dist/icons";
import "./Common.css";
import { useNavigate } from "react-router-dom";
import LoginRegister from "./LoginRegister/LoginRegister";
export interface loginI {
  onClick?: (value: any) => void;
}
const ResetLink: FC<loginI> = ({ onClick = () => {} }: loginI) => {
  const showToast = useToast();
  const navigate = useNavigate();
  const [resetLink, setResetLink] = useState(false);
  const showPassword = () => {
    setTimeout(() => {
      navigate("/resetpassword");
    }, 5000);
  };
  return (
    <LoginRegister>
      <>
        <Text
          customClass="inte-auth_userHeading"
          fontweight="bold"
          type="T-4"
          as="h2"
        >
          Generate Password Reset Link
        </Text>
        <Seprator customClass="inte-auth_headerDevider" />

        <div className="inte-auth__wrapper">
          {resetLink && (
            <Alert
              type="success"
              icon={<CheckCircle size={20} />}
              title="Password Reset link sent!"
              description="Check your email to reset password."
              hasDestroy
            />
          )}
          {!resetLink && <TextField placeHolder="Enter Email" type="text" />}
        </div>
        <Seprator customClass="inte-auth__bottomSeprator" />
        {!resetLink && (
          <Button
            customClass="inte-register__registerButton"
            halign="center"
            content="Generate link"
            isFullWidth
            onClick={() => {
              setResetLink(!resetLink);
              showPassword();
              showToast({
                message: "Redirecting to Reset password page .......",
                type: "success",
                timeout: 5000,
                showProgressBar: true,
              });
            }}
          />
        )}

        <Button
          icon={<ArrowLeft size="20" />}
          type="textButton"
          content="Back to Login"
          customClass="inte-auth__backLogin"
          onClick={() => navigate("/login")}
        />
      </>
    </LoginRegister>
  );
};

export default ResetLink;
