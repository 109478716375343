import {
  Card,
  RadioGroup,
  ToggleGroup,
  Checkbox,
  Seprator,
  FlexLayout,
  Text,
  FlexChild,
  TextField,
  Button,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import { ArrowRight } from "@cedcommerce-integration/ounce/dist/icons";
import { useNavigate } from "react-router";

function Configuration() {
  const {width}=useWindowResize()
  const navigate = useNavigate();
  return (
    <FlexLayout childWidth="fullWidth" spacing="extraLoose">
     

      <Card
        spacing="loose"
        cardType="bordered"
        title="General Configuration"
        subTitle="Enable or disable the following basic configuration setting as needed."
      >
        <FlexLayout spacing="mediumLoose" direction="vertical">
          <ToggleGroup
            direction="vertical"
            helpIcon="Search"
            name="Label"
            onChange={() => {}}
            options={[
              {
                label: "Sync Product",
                value: "Toggle1",
              },
              {
                label: "Sync Price",
                value: "Toggle2",
              },
              {
                label: "Create order Automatically",
                value: "Toggle3",
              },
              {
                label: "Sync order shipment",
                value: "Toggle4",
              },
              {
                label: "Blank Box",
                value: "Toggle5",
              },
            ]}
            value={["Toggle1", "Toggle2", "Toggle3", "Toggle5"]}
          />
          <Checkbox
            id="radio-id"
            label="Sync order shipment"
            name="Name"
            onChange={() => {}}
            value="radio-value"
          />
          <Checkbox
            id="radio-id"
            label="Sync Price"
            name="Name"
            onChange={() => {}}
            value="radio-value"
          />
          <RadioGroup
            direction="vertical"
            name="name"
            options={[
              {
                label:
                  "Create an order when the quantity is available for blank box.",
                value: "Radio1",
              },
              {
                label:
                  "Create an order even when the quantity is not available for blank box.",
                value: "Radio2",
              },
            ]}
            value="Radio1"
          />
          <Seprator />
          <FlexLayout>
            <FlexChild desktopWidth="66">
              <FlexLayout direction="vertical" spacing="extraTight">
                <Text children="Match with Tiktok *" />
                <Text
                  textcolor="secondary"
                  children="Atleast one should be selected"
                />
              </FlexLayout>
            </FlexChild>

            <FlexChild desktopWidth="33">
              <FlexLayout spacing="extraLoose" wrap="noWrap">
                <Checkbox label="SKU" checked />
                <Checkbox label="Item Name" checked />
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
          <Seprator />
          <FlexLayout spacing="loose" childWidth="fullWidth">
            <FlexLayout direction="vertical" spacing="mediumTight">
              <Text children="Set Aside Inventory" />
              <Text
                type="T-7"
                textcolor="secondary"
                children="Set aside specific quantities of inventory for Amazon MCF and transfer lower/remaining quantities to Mirakl."
              />
            </FlexLayout>

            <FlexLayout direction="vertical" spacing="mediumTight">
              <TextField />
              <Text children="For example : If you choose to reserve 5 inventory values out of 20, we will send 15 Amazon MCF inventory on Mirakl." />
            </FlexLayout>
          </FlexLayout>
          <Seprator />
          <FlexLayout
                    halign="end"
                    childWidth={width > 768 ? "none" : "fullWidth"}
                  >
                    <Button
                      isFullWidth={width > 768 ? false : true}
                      halign="center"
                      icon={<ArrowRight />}
                      content="Submit"
                      onClick={() => {
                      navigate("/dashboard")
                      }}
                    />
                  </FlexLayout>
        
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
}
export default Configuration;
