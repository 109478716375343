import React, { useEffect, useState } from "react";
import {
  Accordion,
  AnimatingIllustration,
  Button,
  Card,
  FallBack,
  FlexLayout,
  PageHeader,
  Tabs,
  Text,
  TextField,
} from "@cedcommerce-integration/ounce";
import { Minus, Plus, Search } from "@cedcommerce-integration/ounce/dist/icons";

const Faq = () => {
  const [selectedTab, setSelectedTab] = useState<string>("Amazon");
  const [showFaq, setShowFaq] = useState<number>(3);
  const [clicked, setClicked] = useState<number>(-1);
  const [autoCompleteData, setAutoCompleteData] = useState<any>([
    {
      label: "",
      value: "",
    },
  ]);
  const handleToggle = (index: number) => {
    if (clicked === index) {
      setClicked(-1);
    } else {
      setClicked(index);
    }
  };
  const tabsData = [
    {
      key: "Amazon",
      label: "Amazon",
    },

    {
      key: "Ebay",
      label: "Ebay",
    },
    {
      key: "Walmart",
      label: "Walmart",
    },
  ];
  const renderTabsData: { [key: string]: JSX.Element } = {
    Amazon: (
      <FlexLayout direction="vertical" spacing="mediumLoose">
        <Card cardType="bordered">
          {autoCompleteData
            .slice(0, showFaq)
            .map((items: any, index: number) => {
              return (
                <Accordion
                  title={items.label}
                  key={index}
                  isActive={clicked === index}
                  onClick={() => handleToggle(index)}
                >
                  <Text textcolor="secondary">{items.value}</Text>
                </Accordion>
              );
            })}
        </Card>
        <FlexLayout halign="center">
          <Button
            onClick={() => {
              showFaq <= 3
                ? setShowFaq(autoCompleteData.length)
                : setShowFaq(3);
            }}
            type="outlined"
            content={`${
              showFaq <= 3 ? "View more questions" : "Less more questions"
            }`}
            icon={showFaq <= 3 ? <Plus /> : <Minus />}
          />
        </FlexLayout>
      </FlexLayout>
    ),
    Ebay: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No FAQ Found"
          subTitle="FAQ's will be shown once they are added"
          illustration={<AnimatingIllustration animationData="Journal" loop />}
        />
      </FlexLayout>
    ),
    Walmart: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No FAQ Found"
          subTitle="FAQ's will be shown once they are added"
          illustration={<AnimatingIllustration animationData="Journal" loop />}
        />
      </FlexLayout>
    ),
  };

  useEffect(() => {
    let holdData: any = [];
    fetch("https://dummyjson.com/products?&limit=100")
      .then((res) => res.json())
      .then((res) => {
        res?.products?.map((item: any, index: number) => {
          return (holdData = [
            ...holdData,
            {
              label: item.title,
              value: item.description,
            },
          ]);
        });
        setAutoCompleteData(holdData);
      });
  }, []);
  return (
    <>
      <PageHeader
        title="FAQs"
        description="Get instant help from CedCommerce’s team of experts. Solve any problem you encounter."
        customClass="inte-custom__pageHeader"
      />

      <FlexLayout direction="vertical" spacing="extraLoose">
        <TextField placeHolder="Search faq" prefix={<Search size={20} />} />

        <Tabs
          direction="horizontal"
          tabs={tabsData}
          value={selectedTab}
          onChange={(newTab: any) => setSelectedTab(newTab)}
          customClass="inte-custom__tabs"
        >
          {renderTabsData[selectedTab as string]}
        </Tabs>
      </FlexLayout>
    </>
  );
};

export default Faq;
