export const Logo = (
  <svg width="60" height="60" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="36" height="36" rx="6.75" fill="url(#paint0_linear_540_30976)"/>
  <path d="M21.2192 18.1246L26.937 12.4283L19.8036 5.33084L11.1713 13.9123L6.44348 18.5947L19.6833 31.7941L27.3071 24.1989L21.2192 18.1246ZM9.33016 18.6039L19.7944 8.20668L24.0319 12.4283L19.7666 16.6775L18.1012 15.0184L16.6579 16.4563L18.3233 18.1154L14.6502 21.7747L16.0935 23.2127L19.7666 19.5533L24.4112 24.1805L19.6833 28.9183L9.33016 18.6039Z" fill="url(#paint1_linear_540_30976)"/>
  <defs>
  <linearGradient id="paint0_linear_540_30976" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
  <stop stopColor="white"/>
  <stop offset="1" stopColor="#D0D5DD"/>
  </linearGradient>
  <linearGradient id="paint1_linear_540_30976" x1="37.1256" y1="12.375" x2="-1.68695" y2="14.0625" gradientUnits="userSpaceOnUse">
  <stop stopColor="#100F18"/>
  <stop offset="1" stopColor="#54538C"/>
  </linearGradient>
  </defs>
  </svg>
  );

  export const RoundTick=(
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#F0EDFA"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M14.9861 6.63886C15.2546 6.90735 15.2546 7.34265 14.9861 7.61114L9.48614 13.1111C9.21765 13.3796 8.78235 13.3796 8.51386 13.1111L5.76386 10.3611C5.49538 10.0927 5.49538 9.65735 5.76386 9.38886C6.03235 9.12038 6.46765 9.12038 6.73614 9.38886L9 11.6527L14.0139 6.63886C14.2824 6.37038 14.7177 6.37038 14.9861 6.63886Z" fill="#413BBC"/>
</svg>
  )