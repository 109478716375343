import { PageHeader } from "@cedcommerce-integration/ounce";
import Activities from "./Activites";

function Notification() {
    return (
        <>
            <PageHeader title="Notification" />
            <Activities />
        </>
    )
}
export default Notification