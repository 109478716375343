import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  DataTable,
  FlexChild,
  FlexLayout,
  Modal,
  PageHeader,
  Seprator,
  Text,
  Thumbnail,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import { Plus } from "@cedcommerce-integration/ounce/dist/icons";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTags from "./CustomTags";
import "./Product.css";

const ViewProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowResize();
  const [showHoldImage, setShowHoldImage] = useState(false);
  const [imageLink, setImageLink] = useState(location.state.imgLink);
  const [tableDataRow, setTableRowData] = useState([
    {
      id: 1,
      key: "1",
      name: "",
    },
  ]);

  useEffect(() => {
    let holdData: any = [];
    fetch(`https://dummyjson.com/products/${location.state.id}`)
      .then((res) => res.json())
      .then((json) => {
        json?.images?.map((imgUrl: string, index: number) => {
          return (holdData = [
            ...holdData,
            {
              id: index,
              key: `${index}`,
              image: <Thumbnail alt="Image Data Not Found" src={imgUrl} />,
              name: json.title,
              retailPrice: (
                <Text type="T-7" fontweight="bold" textcolor="success">
                  ${json.price}
                </Text>
              ),
              inventory: <Text type="T-7">{json.stock} in Stock</Text>,
              weight: <Text type="T-7">0.6</Text>,
              status: (
                <Badge type="warning" variant="accent">
                  Inactive
                </Badge>
              ),
            },
          ]);
        });
        setTableRowData(holdData);
      });
  }, []);

  const showingModal = () => {
    return tableDataRow?.slice(0, 3)?.map((imgUrl: any, index: number) => {
      return (
        <div
          className={`inte-thumbnail__container ${
            width <= 768 ? "inte-thumbnail__mobile" : ""
          }`}
          key={index}
          onClick={() => {
            index === 2 &&
              tableDataRow.length > 3 &&
              setShowHoldImage(!showHoldImage);
            setImageLink(imgUrl?.image?.props?.src);
          }}
        >
          {index === 2 && tableDataRow.length > 3 && (
            <div
              className="inte-thumbnail__overLay"
              key={index}
              onClick={() => index === 2 && setShowHoldImage(!showHoldImage)}
            >
              <Plus size="24" color="#fff" />

              {tableDataRow.length - 3}
            </div>
          )}
          <Thumbnail size="large" src={imgUrl?.image?.props?.src} />
        </div>
      );
    });
  };

  return (
    <>
      <PageHeader
        title="View Product"
        hasReverseNavigation
        customClass="inte-custom__pageHeader"
        onClick={() => navigate("/product/productlist")}
      />
      <FlexLayout direction="vertical" spacing="extraLoose" wrap="noWrap">
        {width > 768 && (
          <Card cardType="bordered">
            <FlexLayout spacing="mediumLoose">
              <FlexChild tabWidth="100" desktopWidth="33" mobileWidth="100">
                <FlexLayout spacing="loose" valign="stretch">
                  <FlexChild tabWidth="25" desktopWidth="25" mobileWidth="100">
                    <FlexLayout
                      direction="vertical"
                      spacing="mediumTight"
                      halign="fill"
                      wrap="noWrap"
                    >
                      {showingModal()}
                    </FlexLayout>
                  </FlexChild>

                  <FlexChild tabWidth="75" desktopWidth="75" mobileWidth="100">
                    <Thumbnail
                      alt="Image Data Not Found"
                      size="extraLarge"
                      src={`${imageLink}`}
                      customClass="inte-custom__thumbnail"
                    />
                  </FlexChild>
                </FlexLayout>
              </FlexChild>
              <FlexChild tabWidth="100" desktopWidth="66" mobileWidth="100">
                <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
                  <FlexLayout spacing="tight">
                    <Text type="T-7" fontweight="bold">
                      Nike Legacy Women's Shoes Size
                    </Text>
                    <Badge type="warning" variant="accent">
                      Live
                    </Badge>
                  </FlexLayout>
                  <FlexLayout
                    direction="vertical"
                    spacing="tight"
                    wrap="noWrap"
                  >
                    <Text type="T-8">
                      Centrino casual shoe material: synthetic material. These
                      shoes are made up of high quality synthetic material stays
                      strong and durable - meaning the shoes will last much
                      longer. Lifestyle: formal/ business. Pair these stylish
                      Centrino shoes with your shirt & trousers for that perfect
                      look. Office wear, office shoes, formal shoes, Slip-on,
                      business shoes. Closure: slip-on.
                    </Text>

                    <FlexLayout spacing="tight">
                      <CustomTags text="SKU:" value="215454642" />
                      <CustomTags text="Price:" value="$200.00" />
                      <CustomTags text="Price:" value="$200.00" />
                      <CustomTags text="Brand:" value="Company 123" />
                      <CustomTags text="Tags:" value="Beads, Footwear, Shoes" />
                      <CustomTags
                        text="Category:"
                        value="Mobilier d'intérieur > Linge de maison > Footwear"
                      />
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </FlexChild>
            </FlexLayout>
          </Card>
        )}

        {width <= 768 && (
          <FlexLayout direction="vertical" spacing="loose">
            <FlexLayout direction="vertical" spacing="tight">
              <FlexLayout spacing="tight">
                <Text type="T-7" fontweight="bold">
                  Nike Legacy Women's Shoes Size
                </Text>
                <Badge type="success" variant="accent">
                  Live
                </Badge>
              </FlexLayout>

              <FlexLayout spacing="mediumTight">{showingModal()}</FlexLayout>

              <Seprator />
              <FlexLayout direction="vertical" spacing="mediumTight">
                <Text type="T-8" fontweight="bold">
                  Description
                </Text>
                <Text type="T-8">
                  Centrino casual shoe material: synthetic material. These shoes
                  are made up of high quality synthetic material stays strong
                  and durable - meaning the shoes will last much longer.
                  Lifestyle: formal/ business. Pair these stylish Centrino shoes
                  with your shirt & trousers for that perfect look. Office wear,
                  office shoes, formal shoes, Slip-on, business shoes. Closure:
                  slip-on.
                </Text>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout spacing="tight">
              <CustomTags text="SKU:" value="215454642" />
              <CustomTags text="Price:" value="$200.00" />
              <CustomTags text="Price:" value="$200.00" />
              <CustomTags text="Brand:" value="Company 123" />
              <CustomTags text="Tags:" value="Beads, Footwear, Shoes" />
              <CustomTags
                text="Category:"
                value="Mobilier d'intérieur > Linge de maison > Footwear"
              />
            </FlexLayout>
          </FlexLayout>
        )}
        {tableDataRow?.length > 1 && (
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <Text type="T-5" fontweight="bold">
              Product Variants
            </Text>
            <DataTable
              dataSource={tableDataRow}
              columns={[
                {
                  dataIndex: "image",
                  key: "image",
                  width: 80,
                  title: "Image",
                },
                {
                  dataIndex: "name",
                  key: "name",
                  title: "Name",
                },
                {
                  dataIndex: "retailPrice",
                  key: "retailPrice",
                  title: "Retail Price",
                },
                {
                  dataIndex: "inventory",
                  key: "inventory",
                  title: "Inventory",
                },
                {
                  dataIndex: "weight",
                  key: "weight",
                  title: "Weight (Kg)",
                },
                {
                  dataIndex: "status",
                  key: "status",
                  title: "Status",
                  fixed: "right",
                },
              ]}
            />
          </FlexLayout>
        )}
      </FlexLayout>
      <Modal
        isOpen={showHoldImage}
        onClose={() => setShowHoldImage(false)}
        heading="View All Images"
      >
        <FlexLayout spacing="loose">
          {tableDataRow?.map((imgUrl: any, index: number) => {
            return (
              <Thumbnail
                key={index}
                size="extraLarge"
                src={imgUrl?.image?.props?.src}
              />
            );
          })}
        </FlexLayout>
      </Modal>
    </>
  );
};

export default ViewProduct;
