import {
  Button,
  Card,
  FlexChild,
  FlexLayout,
  StepWizard,
  Tabs,
  Text,
  ToolTip,
} from "@cedcommerce-integration/ounce";
import "../Onboarding/Onboard.css";
import { Logo } from "../assests/icons/Assests";
import { useState } from "react";
import SelectedPlan from "./SelectedPlan";
import SlideAdded from "../Pricing/Slide";
import { ChevronRight, Info } from "@cedcommerce-integration/ounce/dist/icons";
function Onbording4() {
  const [step, setStep] = useState(1);
  const [selectedTab, setSelectedTab] = useState<string>("regular");
  return (
    <>
      <FlexLayout
        desktopWidth="66"
        valign="center"
        direction="vertical"
        mobileWidth="100"
      >
        <Card customClass="pt40" cardType="borderLess">
          <FlexLayout wrap="noWrap" spacing="loose" customClass="mb-36">
           {Logo}
            <FlexChild>
              <FlexLayout direction="vertical">
                <Text type="T-4" fontweight="bolder">
                  Welcome to TikTok Shop Integration
                </Text>
                <Text type="T-4" textcolor="secondary">
                  {" "}
                  By CedCommerce
                </Text>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
          <FlexLayout
            spacing="tight"
            direction="vertical"
            desktopWidth="100"
            mobileWidth="100"
            tabWidth="100"
          >
            <StepWizard
              currentStep={step}
              direction="horizontal"
              onChange={(newStep) => {
                // setStep(newStep)
              }}
              size="small"
              steps={[
                {
                  label: "Connect (App Name)",
                  value: "Step1",
                },
                {
                  label: "Connect (App Name) Shop",
                  value: "Step2",
                },
                {
                  label: "Configuration",
                  value: "Step3",
                },
              ]}
            />

            {step === 1 && (
              <Card
                cardType="bordered"
                title="Pick the plan that suits you best"
                subTitle="Choose a plan that suits you best at the moment to continue with onboarding process. You have the flexibility to upgrade or downgrade your plan according to your requirements at any time in the future, after onboarding."
              >
                <FlexLayout
                  spacing="mediumLoose"
                  direction="vertical"
                  desktopWidth="100"
                  tabWidth="100"
                  mobileWidth="100"
                >
                  <Tabs
                 spacing="mediumLoose"
                   onChange={(newTabKey) => setSelectedTab(newTabKey)}
                    tabs={[
                      {
                        key: "regular",
                        label: "Regular Plan",
                      },
                      {
                        key: "bussiness",
                        label: "Business Plan",
                      },
                    ]}
                    value={selectedTab}
                  >
                  {selectedTab==="regular"?<SlideAdded />:<SlideAdded/>}
                  </Tabs>
                  <FlexLayout spacing="extraTight" halign="end" valign="center">
                    <ToolTip
                      helpText="This plan include minimum 10 oders per month. Basic feautres to update inventory."
                      activator={<Info size={20} />}
                    />
                    <Button
                      onClick={() => setStep(2)}
                      iconAlign="right"
                      content="Skip with free plan"
                      type="textButton"
                      icon={<ChevronRight size={20} />}
                    />
                  </FlexLayout>
                </FlexLayout>
              </Card>
            )}
            {step === 2 && <SelectedPlan />}
          </FlexLayout>
        </Card>
      </FlexLayout>
    </>
  );
}

export default Onbording4;
