import {
  Button,
  Card,
  Checkbox,
  FlexChild,
  FlexLayout,
  Modal,
  Seprator,
  StepWizard,
  Text,
  TextField,
  TextLink,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import { Play, Download } from "@cedcommerce-integration/ounce/dist/icons";
import "../Onboarding/Onboard.css";
import { Logo } from "../Onboarding/icons";
import { useState } from "react";
import CreateDefault from "./CreateDefault";
import CreateDefault2 from "./CreateDefault2";
function Onboarding3() {
  const { width } = useWindowResize();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  return (
    <>
      <FlexLayout desktopWidth="66" valign="center" direction="vertical">
        <Card customClass="pt40" cardType="borderLess">
          <FlexLayout wrap="noWrap" spacing="loose" customClass="mb-36">
            <div className="inte-logo">{Logo}</div>
            <FlexChild>
              <FlexLayout direction="vertical">
                <Text type="T-4" fontweight="bolder">
                  Welcome to TikTok Shop Integration
                </Text>
                <Text type="T-4" textcolor="secondary">
                  {" "}
                  By CedCommerce
                </Text>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
          <FlexLayout spacing="tight" direction="vertical">
            <StepWizard
              currentStep={step}
              direction="horizontal"
              onChange={(newStep) => {
                // setStep(newStep)
              }}
              size="small"
              steps={[
                {
                  label: "Connect Wallmart",
                  value: "Step1",
                },
                {
                  label: "Default Template",
                  value: "Step2",
                },
                {
                  label: "Configuration",
                  value: "Step3",
                },
              ]}
            />

            {step === 1 && (
              <Card
                cardType="bordered"
                title="Connect Walmart Account"
                subTitle="Connect your Walmart Seller account to effortlessly manage products and orders through the app."
                action={
                  width < 768 ? (
                    <Button type="outlined" icon={<Play size={20} />} />
                  ) : (
                    <Button
                      type="outlined"
                      content="View Guide"
                      icon={<Play />}
                    />
                  )
                }
              >
                <Modal
                  heading="Onboarding Guide"
                  modalSize="large"
                  primaryAction={{
                    content: "Download Guide ",
                    icon: <Download size={20} />,
                  }}
                  isOpen={open}
                  onClose={() => setOpen(false)}
                >
                  Content goes here
                </Modal>
                <FlexLayout direction="vertical" spacing="mediumLoose">
                  <Seprator />
                  <TextField
                    label="Client ID "
                    isRequired
                    placeHolder="Enter Text here"
                  />
                  <TextField
                    label="Secret Key"
                    isRequired
                    placeHolder="Enter Text here"
                  />
                  <FlexLayout direction="vertical" directionMob="vertical-reverse" spacing="mediumLoose">
                  <Checkbox
                    label={
                      <FlexLayout valign="center">
                        <Text type="T-7" children="I agree to the" />
                        <TextLink label="Terms and Condition" />
                      </FlexLayout>
                    }
                    onChange={() => {}}
                    value="radio-value"
                  />
                  <Seprator />
                  <FlexLayout
                    directionMob="vertical"
                    halign="fill"
                    valign="center"
                    valignMob="start"
                  >
                    <FlexLayout>
                      <Text type="T-7" children="Don’t have an account?" />
                      <TextLink label="Create new" />
                    </FlexLayout>
                    {width > 768 && (
                      <FlexLayout
                        halign="end"
                        childWidth={width > 768 ? "none" : "fullWidth"}
                      >
                        <Button content="Connect" onClick={() => setStep(2)} />
                      </FlexLayout>
                    )}
                  </FlexLayout>
                  </FlexLayout>
                  {width < 768 && (
                    <Button
                      isFullWidth
                      content="Connect"
                      onClick={() => setStep(2)}
                      halign="center"
                    />
                  )}
                </FlexLayout>
              </Card>
            )}
            {step === 2 && <CreateDefault onStepChange={() => setStep(3)} />}
            {step === 3 && <CreateDefault2 />}
          </FlexLayout>
        </Card>
      </FlexLayout>
    </>
  );
}

export default Onboarding3;
