import { Card, FlexLayout, TextLink} from '@cedcommerce-integration/ounce'
import StatusCard from './StatusCard'
import React from 'react'

const ProductStatus = () => {
  const cardArr = [
    {
      number: 10,
      title: "Live",
      desc: "Products",
      color: "green"
    },
    {
      number: 567,
      title: "Failed",
      desc: "Products",
      color: "red"
    },
    {
      number: 500,
      title: "Not Updated",
      desc: "Products",
      color: "yellow"
    },
    {
      number: 6000,
      title: "Inactive",
      desc: "Products",
      color: "black"
    },
  ]
  return (
    <Card customClass='inte-no-spacing' cardType='borderLess' title='Product Status' action={<TextLink label="View All" />}>
      <FlexLayout desktopWidth='25' mobileWidth='50' spacing='loose'>
         {
          cardArr.map((item , i) => <React.Fragment key={i}><StatusCard {...item} /></React.Fragment>)
          }
        </FlexLayout>
    </Card>
  )
}

export default ProductStatus