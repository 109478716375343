import { Badge, Card, FlexLayout, Progressbar, Text, TextLink } from '@cedcommerce-integration/ounce'

const PricingInfo = () => {
  return (
    <Card
        customClass="header-card"
        cardType="bordered"
        title="Pricing Info"
        spacing='tight'
        action={<TextLink label="Upgrade Plan" />}
      >
        <FlexLayout direction='vertical' spacing='loose'>
          <FlexLayout halign='fill'>
            <Text type='T-7' textcolor='subdued'>Active plan</Text>
            <Text type='T-7' fontweight='bold'>Starter - Billed Monthly</Text>
          </FlexLayout>
          <FlexLayout halign='fill'>
            <Text type='T-7' textcolor='subdued'>Plan amount</Text>
            <Text type='T-7' fontweight='bold'>$25.00</Text>
          </FlexLayout>
          <FlexLayout halign='fill'>
            <Text type='T-7' textcolor='subdued'>Plan status</Text>
            <Badge type='success' variant='accent'>Paid</Badge>
          </FlexLayout>
          <FlexLayout halign='fill'>
            <Text type='T-7' textcolor='subdued'>Billing date</Text>
            <Text type='T-7' fontweight='bold'>June 20, 2023</Text>
          </FlexLayout>
          <FlexLayout halign='fill'>
            <Text type='T-7' textcolor='subdued'>Product limit</Text>
           <FlexLayout direction='vertical' spacing='extraTight'>
            <Progressbar percentage={12} />
           <Text type='T-7'>12 of 100</Text>
           </FlexLayout>
          </FlexLayout>
          <FlexLayout halign='fill'>
            <Text type='T-7' textcolor='subdued'>Monthly order limit</Text>
            <FlexLayout direction='vertical' spacing='extraTight'>
              <Progressbar percentage={0}/>
              <Text type='T-7'>0 of 100</Text>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </Card>
  )
}

export default PricingInfo