import { AspectRatio, Badge, Card, FlexLayout, Text } from '@cedcommerce-integration/ounce'
import amazon from '../../../assests/images/amazon.png'
import tictok from "../../../assests/images/tiktok.png"

const ConnectedChannel = () => {
  return (
    <Card
      cardType="bordered"
      spacing="tight"
      title="Connected Channels"
      customClass="header-card"
    >
      <FlexLayout halign="fill" spacing="loose" direction="vertical">
        <FlexLayout halign="fill" valign="center">
          <FlexLayout valign="center" spacing="mediumTight">
            <AspectRatio style={{width : "32px"}} ratio={1} >
              <img src={amazon} alt='amazon' />
            </AspectRatio>
            <Text fontweight="bold">Amazon</Text>
          </FlexLayout>
          <Badge type="success" variant="accent">Connected</Badge>
        </FlexLayout>
        <FlexLayout halign="fill" valign="center">
          <FlexLayout valign="center" spacing="mediumTight">
          <AspectRatio style={{width : "32px"}} ratio={1} >
              <img src={tictok} alt='tictok' />
            </AspectRatio>
            <Text fontweight="bold">TikTok</Text>
          </FlexLayout>
          <Badge type="success" variant="accent">Connected</Badge>
        </FlexLayout>
      </FlexLayout>
    </Card>
  )
}

export default ConnectedChannel