import React from "react";
import {
  Avatar,
  Card,
  FlexChild,
  FlexLayout,
  PageHeader,
  Seprator,
  Text,
  Thumbnail,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import { Phone, Mail } from "@cedcommerce-integration/ounce/dist/icons";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "./CustomTable/CustomTable";
import Table from "./Table";
import "./Order.css";

const ViewOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowResize();

  return (
    <>
      <PageHeader
        title="577714493044460294"
        hasReverseNavigation
        customClass="inte-custom__pageHeader"
        onClick={() => navigate("/orders/orderlist")}
        titleBadge={{
          children: "Awaiting Shipment",
          size: "small",
          type: "warning",
          variant: "accent",
        }}
      />
      <Card cardType={width < 768 ? "borderLess" : "bordered"}>
        <FlexLayout spacing="mediumLoose">
          <FlexChild desktopWidth="66" tabWidth="66" mobileWidth="100">
            <FlexLayout
              direction="vertical"
              spacing="mediumLoose"
              wrap="noWrap"
            >
              {width >= 768 ? (
                <Table id={location.state} />
              ) : (
                <CustomTable id={location.state} />
              )}
            </FlexLayout>
          </FlexChild>
          <FlexChild desktopWidth="33" tabWidth="33" mobileWidth="100">
            <FlexLayout direction="vertical" spacing="mediumLoose">
              <Card cardType="bordered">
                <FlexLayout direction="vertical" spacing="loose">
                  <Text type="T-7" fontweight="bold">
                    Customer Information
                  </Text>
                  <FlexLayout spacing="loose" valign="center">
                    <Avatar
                      image="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                      size="medium"
                    />
                    <FlexLayout direction="vertical" spacing="extraTight">
                      <Text>Name</Text>
                      <Text textcolor="secondary">Tanya McCarthy</Text>
                    </FlexLayout>
                  </FlexLayout>

                  <Seprator />

                  <FlexLayout spacing="loose">
                    <Mail />
                    <Text type="T-7" textcolor="secondary">
                      Customer Information
                    </Text>
                  </FlexLayout>
                  <FlexLayout spacing="loose">
                    <Phone />
                    <Text type="T-7" textcolor="secondary">
                      (+442)7587451258
                    </Text>
                  </FlexLayout>
                </FlexLayout>
              </Card>
              <Card cardType="bordered">
                <FlexLayout direction="vertical" spacing="loose">
                  <Text type="T-7" fontweight="bold">
                    Shipping Method
                  </Text>
                  <FlexLayout spacing="loose" valign="center">
                    <Thumbnail
                      size="medium"
                      src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                    />

                    <FlexLayout direction="vertical" spacing="extraTight">
                      <Text>Amazon Order ID</Text>
                      <Text textcolor="secondary">1987650</Text>
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </Card>

              <Card cardType="bordered">
                <FlexLayout direction="vertical" spacing="loose">
                  <Text type="T-7" fontweight="bold">
                    Order ID
                  </Text>
                  <FlexLayout spacing="loose" valign="center">
                    <Thumbnail
                      size="medium"
                      src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                    />

                    <FlexLayout direction="vertical" spacing="extraTight">
                      <Text>Amazon Order ID</Text>
                      <Text textcolor="secondary">1987650</Text>
                    </FlexLayout>
                  </FlexLayout>
                  <FlexLayout spacing="loose" valign="center">
                    <Thumbnail
                      size="medium"
                      src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                    />

                    <FlexLayout direction="vertical" spacing="extraTight">
                      <Text textcolor="default">Shopline Order ID</Text>
                      <Text textcolor="secondary">1987650</Text>
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </Card>
              <Card cardType="bordered">
                <FlexLayout direction="vertical" spacing="loose">
                  <Text type="T-7" fontweight="bold">
                    Shipping Address
                  </Text>
                  <FlexLayout direction="vertical" spacing="extraTight">
                    <Text type="T-7">Katiyar Ankush, 7587451258</Text>
                    <Text type="T-7" textcolor="secondary">
                      CedCommerce A 1/259 Gomti Nagar, Lucknow Uttar Pradesh
                      226010
                    </Text>
                  </FlexLayout>
                </FlexLayout>
              </Card>
            </FlexLayout>
          </FlexChild>
        </FlexLayout>
      </Card>
    </>
  );
};

export default ViewOrder;
