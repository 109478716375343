import React, { useEffect, useRef } from 'react';
import { BubbleDataPoint, Chart, ChartTypeRegistry } from 'chart.js/auto';
import { Badge, FlexChild, FlexLayout, Text } from '@cedcommerce-integration/ounce';
import { Point } from 'chart.js/dist/core/core.controller';

interface DonutChartProps {
  data: number[];
  labels: string[];
}

const DonutChart: React.FC<DonutChartProps> = ({ data, labels }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destroy the previous chart instance, if it exists
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        const newChartInstance = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels,
            datasets: [
              {
                data,
                backgroundColor: ["#FEC84B", "#413BBC", '#1B875A', '#C4281C', "#2E2E2E"],
                borderWidth: 0,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            cutout: '80%',
            // Other chart options
          },
        });

        chartInstanceRef.current = newChartInstance as Chart<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>;

      }
    }
  }, [data, labels]);


  return <FlexLayout halign='fill' direction='vertical' spacing='loose' wrap='noWrap' childWidth='fullWidth'>
    <FlexChild>
      <div>
        <div className='donught-chart'>
          <canvas ref={chartRef} />
          <div className='donughtCard-center-details'>
            <Text type='T-4' fontweight='bold'>$75.5k</Text>
            <Badge type='success' variant='accent'>+10%</Badge>
          </div>
        </div>
      </div>
    </FlexChild>
    <FlexChild>
      <FlexLayout spacing='tight' direction='vertical' childWidth='fullWidth' wrap='noWrap'>
        <FlexLayout spacing='mediumTight'>
          <FlexChild childWidth='fullWidth'>
            <FlexLayout valign='center' spacing='mediumTight'>
              <Badge dot size='large' customBgColor="var(--inte-Y300)" />
              <Text fontweight='bold'>Not Published</Text>
            </FlexLayout>
          </FlexChild>
          <Text fontweight='bold' type='T-6'>2213</Text>
        </FlexLayout>
        <FlexLayout spacing='mediumTight'>
          <FlexChild childWidth='fullWidth'>
            <FlexLayout valign='center' spacing='mediumTight'>
              <Badge dot size='large' customBgColor="var(--inte-P900)" />
              <Text fontweight='bold'>Published</Text>
            </FlexLayout>
          </FlexChild>
          <Text fontweight='bold' type='T-6'>2213</Text>
        </FlexLayout>
        <FlexLayout spacing='mediumTight'>
          <FlexChild childWidth='fullWidth'>
            <FlexLayout valign='center' spacing='mediumTight'>
              <Badge dot size='large' customBgColor="var(--inte-GR200)" />
              <Text fontweight='bold'>Active</Text>
            </FlexLayout>
          </FlexChild>
          <Text fontweight='bold' type='T-6'>2213</Text>
        </FlexLayout>
        <FlexLayout spacing='mediumTight'>
          <FlexChild childWidth='fullWidth'>
            <FlexLayout valign='center' spacing='mediumTight'>
              <Badge dot size='large' customBgColor="var(--inte-R400)" />
              <Text fontweight='bold'>Error</Text>
            </FlexLayout>
          </FlexChild>
          <Text fontweight='bold' type='T-6'>2213</Text>
        </FlexLayout>
        <FlexLayout spacing='mediumTight'>
          <FlexChild childWidth='fullWidth'>
            <FlexLayout valign='center' spacing='mediumTight'>
              <Badge dot size='large' customBgColor="#2E2E2E" />
              <Text fontweight='bold'>Expired</Text>
            </FlexLayout>
          </FlexChild>
          <Text fontweight='bold' type='T-6'>2213</Text>
        </FlexLayout>
      </FlexLayout>
    </FlexChild>
  </FlexLayout>;
};

export default DonutChart;
