import { Announcement, Text, Card, FlexLayout, Notification, Tabs, Progressbar } from "@cedcommerce-integration/ounce"
import { useState } from "react";
function Activities() {

    const [selectedTab, setSelectedTab] = useState("General");
    const notiData = [
        {
            date: "Yesterday",
            desciption:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet.",
            title: "The product upload has been finished.",
            type: "success",
        },
        {
            date: "Yesterday",
            desciption:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet.",
            title: "The product upload has been finished.",
            type: "warning",
        },
        {
            date: "Yesterday",
            desciption:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet.",
            title: "The product upload has been finished.",
            type: "danger",
        },
        {
            date: "Yesterday",
            desciption:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet.",
            title: "The product upload has been finished.",
            type: "info",
        },
    ];

    const annData = [
        {
            date: "29 Mar 2023",
            desciption:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim",
            title: "Announcement With Description",
        },
        {
            date: "29 Mar 2023",
            desciption:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim",
            title: "Announcement With Description",
        },
        {
            date: "29 Mar 2023",
            desciption:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim",
            title: "Announcement With Description",
        },
        {
            date: "29 Mar 2023",
            desciption:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim",
            title: "Announcement With Description",
        },
    ];
    const renderTabsData = () => {
        switch (selectedTab) {
            case "General":
                return (
                    <>
                        {notiData.map((items: any, index: number) => {
                            return (
                                <Notification
                                    key={index}
                                    date={items.date}
                                    desciption={items.desciption}
                                    title={items.title}
                                    type={items.type}
                                />
                            );
                        })}
                    </>
                );
            case "Recent Activites":
                return (
                    <>
                        <FlexLayout direction="vertical" spacing="mediumLoose">

                            <FlexLayout direction="vertical" spacing="extraTight">
                                <Text>
                                    Product Syncing in progress
                                </Text>
                                <Text type="T-8" textcolor="secondary">
                                    October 10, 2022, 10:03 GMT
                                </Text>
                            </FlexLayout>
                            <Progressbar percentage={30}
                                progessSize="thin" />
                        </FlexLayout>
                        {notiData.map((items: any, index: number) => {
                            return (
                                <Notification
                                    key={index}
                                    date={items.date}
                                    desciption={items.desciption}
                                    title={items.title}
                                    type={items.type}
                                />
                            );
                        })}
                    </>
                );
            case "Announcements":
                return (
                    <>
                        {annData.map((items: any, index: number) => {
                            return (
                                <Announcement
                                    key={index}
                                    date={items.date}
                                    desciption={items.desciption}
                                    title={items.title}
                                    badge={{
                                        children: "New",
                                        type: "success",
                                        variant: "accent",
                                    }}
                                />
                            );
                        })}
                    </>
                );
        }
    };
    return (
        <>
            <Tabs
            spacing="mediumLoose"
                direction="horizontal"
                onChange={(newTab: any) => setSelectedTab(newTab)}
                tabs={[
                    {
                        key: 'General',
                        label: 'General'
                    },
                    {
                        key: 'Recent Activites',
                        label: 'Recent Activites'
                    },
                    {
                        key: 'Announcements',
                        label: 'Announcements'
                    }
                ]}
                value={selectedTab}
            >

                <Card spacing="loose" >
                    {renderTabsData()}
                </Card>
            </Tabs>

        </>
    )
}
export default Activities