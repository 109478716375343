import {
  Alert,
  Button,
  Card,
  FlexChild,
  FlexLayout,
  Select,
  Seprator,
  Text,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import {
  Play,
  ArrowRight,
  AlertCircle,
} from "@cedcommerce-integration/ounce/dist/icons";
import { useState } from "react";
interface StepI {
  onStepChange: () => void;
}
function CreateDefault({ onStepChange }: StepI) {
  const { width } = useWindowResize();
  const [value, setValue] = useState("");

  return (
    <Card
      cardType="bordered"
      title="Create Default Template"
      spacing="loose"
      subTitle="Default template to upload product(s) on ( Shop Name)"
      action={
        width < 768 ? (
          <Button type="outlined" icon={<Play size={20} />} />
        ) : (
          <Button type="outlined" content="View Guide" icon={<Play />} />
        )
      }
    >
      <FlexLayout direction="vertical" spacing="mediumLoose">
        <Alert
          title="Based on the selected Category you will further map BigCommerce attribue with tiktok attribute"
          icon={<AlertCircle size="20" />}
          type="info"
        />
        <FlexChild>
          <FlexLayout direction="vertical">
            <Text fontweight="bold" type="T-7">
              Select Product Category
            </Text>
            <Text textcolor="secondary">
              Default template to upload product(s) on TikTok Shop
            </Text>
          </FlexLayout>
        </FlexChild>
        <Select
          options={[
            {
              label: "India",
              value: "india",
            },
            {
              label: "America",
              value: "America",
            },
          ]}
          value={value}
          placeholder={"Select"}
          onChange={(e) => {
            setValue(e);
          }}
        />
        <Seprator />
        <FlexLayout
          halign="end"
          childWidth={width > 768 ? "none" : "fullWidth"}
        >
          <Button
            content="Next"
            isFullWidth={width > 768 ? false : true}
            halign="center"
            icon={<ArrowRight />}
            iconAlign="right"
            onClick={onStepChange}
          />
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
}
export default CreateDefault;
