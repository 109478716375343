import { Accordion, Card, Carousel, FlexLayout, Tabs, Text } from "@cedcommerce-integration/ounce";
import video_guide from '../../../assests/images/video_guide.png'
import React, { useState } from "react";

const Support = () => {
  const [selectedTab, setSelectedTab] = useState<string | number>("faq");

  const [activeAccordian, setActiveAccordian] = useState<string>();

  return (
    <Card cardType="bordered" title="Support">
      <Tabs
        tabs={[
          {
            key: "faq",
            label: "FAQs",
          },
          {
            key: "video",
            label: "Video Guide",
          },
        ]}
        spacing="mediumLoose"
        value={selectedTab}
        onChange={(newTabKey) => setSelectedTab(newTabKey)}
        children={
          selectedTab === "faq" ? (
            <>
              <Accordion
                children={"Error found in 20 products post product upload."}
                title="Error found in 20 products post product upload."
                isActive={activeAccordian === "Error1"}
                onClick={() =>
                  setActiveAccordian(
                    activeAccordian === "Error1" ? "" : "Error1"
                  )
                }
              />
              <Accordion
                children={"Failed to create 200 orders on Shopify."}
                title="Failed to create 200 orders on Shopify."
                isActive={activeAccordian === "Error2"}
                onClick={() =>
                  setActiveAccordian(
                    activeAccordian === "Error2" ? "" : "Error2"
                  )
                }
              />
              <Accordion
                children={"Error found in 20 products post product upload."}
                title="Error found in 20 products post product upload."
                isActive={activeAccordian === "Error3"}
                onClick={() =>
                  setActiveAccordian(
                    activeAccordian === "Error3" ? "" : "Error3"
                  )
                }
              />
              <Accordion
                children={"Failed to create 200 orders on Shopify."}
                title="Failed to create 200 orders on Shopify."
                customClass="without-border-bottom-accordian"
                isActive={activeAccordian === "Error4"}
                onClick={() =>
                  setActiveAccordian(
                    activeAccordian === "Error4" ? "" : "Error4"
                  )
                }
              />
            </>
          ) : (
            <Carousel
              slidesToShow={2.5}
              spaceBetweenSlides={22}
              showArrows={false}
              showDots={false}
              breakpoints={{
                640: {
                  slidesToShow: 1.2,
                  showDots: false,
                  showArrows: false,
                }
              }}
            >
              <Card
                cardType="bordered"
                media={video_guide}
                spacing="tight"
              >
                <FlexLayout direction="vertical" spacing="mediumTight">
                  <Text type="T-7" fontweight="bold">Video Guide</Text>
                  <Text type="T-7" >Placeholder for card text. Enter text into this container to describe the card feature.</Text>
                </FlexLayout>
              </Card>
              <Card
                cardType="bordered"
                media={video_guide}
                spacing="tight"
              >
                <FlexLayout direction="vertical" spacing="mediumTight">
                  <Text type="T-7" fontweight="bold">Video Guide</Text>
                  <Text type="T-7" >Placeholder for card text. Enter text into this container to describe the card feature.</Text>
                </FlexLayout>
              </Card>
              <Card
                cardType="bordered"
                media={video_guide}
                spacing="tight"
              >
                <FlexLayout direction="vertical" spacing="mediumTight">
                  <Text type="T-7" fontweight="bold">Video Guide</Text>
                  <Text type="T-7" >Placeholder for card text. Enter text into this container to describe the card feature.</Text>
                </FlexLayout>
              </Card>
            </Carousel>
          )
        }
      />
    </Card>
  );
};

export default Support;
