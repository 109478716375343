import React from "react";
import { Text, useWindowResize } from "@cedcommerce-integration/ounce";
import { logo, mobileLogo } from "../assets/icons/icons";
import "./LoginRegister.css";

export interface loginRegisterI {
  children: any;
}

const LoginRegister = ({ children }: loginRegisterI) => {
  const { width } = useWindowResize();
  return (
    <div className="inte-loginRegister">
      <div className="inte-auth__leftSide">
        <div className="inte-auth__leftBanner">
          <div className="inte-auth__logo">
            {width > 768 ? logo : mobileLogo}
          </div>
          <>
            <div className="inte-auth__heading">
              <Text type="T-1" fontweight="bold" as="h2">
                Social Ads
              </Text>
              <Text type="T-1" fontweight="bold" as="h2">
                on Buy with Prime
              </Text>
            </div>

            {width > 768 && (
              <>
                <Text
                  type="T-6"
                  fontweight="bolder"
                  as="h3"
                  customClass="inte-auth__social--description"
                >
                  Create Ad campaigns for your products with the “Buy with
                  Prime” badge and drive traffic through tailored social
                  advertising.
                </Text>

                <Text
                  type="T-7"
                  fontweight="bolder"
                  as="h6"
                  customClass="inte-auth__social--footer"
                >
                  ©2023 Need Help? Get Support
                </Text>
              </>
            )}
          </>
        </div>
      </div>
      <div className="inte-auth__rightSide">
        <div className="inte-auth__rightBanner">{children}</div>
      </div>
    </div>
  );
};

export default LoginRegister;
