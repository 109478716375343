import {
  AppBar,
  AppWrapper,
  Avatar,
  Button,
  FlexLayout,
  PageFooter,
  Text,
  TextLink,
} from "@cedcommerce-integration/ounce";
import { Bell } from "@cedcommerce-integration/ounce/dist/icons";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";

const Panel = () => {
  const navigate = useNavigate();

  return (
    <>
      <AppWrapper
        sideBar={<Sidebar />}
        appBar={
          <AppBar
            stickyTop
            connectRight={
              <FlexLayout spacing="tight" wrap="noWrap" valign="center">
                <Button
                  type="outlined"
                  status="error"
                  accessibilityLabel="notification-button"
                  onClick={() => navigate("/notification")}
                  icon={<Bell size="24" color="#1c2433" />}
                />
                <Avatar
                  animateLines={0}
                  color="secondary"
                  customClass=""
                  image=""
                  size="large"
                  text="sky skyler"
                />

                <Text>Label</Text>
              </FlexLayout>
            }
          />
        }
        appFooter={
          <PageFooter
            children={
              <FlexLayout spacing="mediumTight" wrap="wrap">
                <>A CedCommerce Inc Product @2023</>
                <FlexLayout>
                  <>Need Help?</>
                  <TextLink label="Get Support" />
                </FlexLayout>
              </FlexLayout>
            }
          />
        }
      >
        <Outlet />
      </AppWrapper>
    </>
  );
};

export default Panel;
