import {Card} from '@cedcommerce-integration/ounce'
import ProductStatus from './ProductStatus'
import OrderStatus from './OrderStatus'

const Status = () => {
  return (
    <Card
     customClass='inte__p--0'
      cardType='bordered'
    >
        <ProductStatus />
        <OrderStatus />
    </Card>
  )
}

export default Status