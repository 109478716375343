import React, { useState } from 'react'
import { createContext } from 'react'


interface AuthDataContextI {
  userData?: { username: string, password: string },
  addUser: (username: string, password: string) => void
}

const initialValue: AuthDataContextI = {
  userData: JSON.parse(sessionStorage.getItem('userData') ?? JSON.stringify({ username: '', password: "" })),
  addUser: (username: string, password: string) => { }
}

export const AuthDataContext = createContext(initialValue)

interface AuthContextI {
  children: React.ReactNode
}

const AuthContext = ({ children }: AuthContextI) => {
  const [userData, setUserData] = useState<{ username: string, password: string }>(JSON.parse(sessionStorage.getItem('userData') ?? JSON.stringify({ username: '', password: "" })))

  const addUser = (username: string, password: string) => {

    const newData = {
      username: username,
      password: password
    }

    sessionStorage.setItem('userData', JSON.stringify(newData))
    setUserData(newData)
  }

  return (
    <AuthDataContext.Provider
      value={{ userData, addUser }}
    >
      {
        children
      }
    </AuthDataContext.Provider>
  )
}

export default AuthContext