import React, { useState } from "react";
import "./DetailAnalysis.css";
import { Card, Tabs } from "@cedcommerce-integration/ounce";
import DonutChart from "../Chart/DonughtChart";

const DetailAnalysis = () => {
  const [selectedTab, setSelectedTab] = useState<string | number>("product");

  const chartData = [10, 20, 15, 15, 20];
  const chartLabels = [
    "Not Published",
    "Published",
    "Active",
    "Error",
    "Expired",
  ];

  return (
    <Card title="Detailed Analysis" cardType="bordered">
      <Tabs
        tabs={[
          {
            key: "product",
            label: "Product",
          },
          {
            key: "sales",
            label: "Sales",
          },
        ]}
        spacing="mediumLoose"         
        value={selectedTab}
        onChange={(newTabKey) => setSelectedTab(newTabKey)}
      >
        <div className="chart-donught">
          <DonutChart data={chartData} labels={chartLabels} />
        </div>
      </Tabs>
    </Card>
  );
};

export default DetailAnalysis;
