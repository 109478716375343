import React, { useState } from "react";
import {
  Seprator,
  TextField,
  Text,
  Button,
  TextLink,
  Checkbox,
} from "@cedcommerce-integration/ounce";
import { Eye, EyeOff } from "@cedcommerce-integration/ounce/dist/icons";
import { useNavigate } from "react-router-dom";
import { checkIcon } from "./assets/icons/icons";
import LoginRegister from "./LoginRegister/LoginRegister";
import "./Common.css";

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termCondition, setTermCondition] = useState(false);
  return (
    <>
      <LoginRegister>
        <Text
          customClass="inte-auth_userHeading"
          fontweight="bold"
          type="T-4"
          as="h2"
        >
          Create Account
        </Text>
        <Seprator customClass="inte-auth_headerDevider" />

        <div className="inte-auth__wrapper">
          <TextField placeHolder="Enter Organization Name" />
          <TextField type="text" placeHolder="Enter Email" />
          <TextField
            type={showPassword ? "text" : "password"}
            placeHolder="Enter Password"
            suffix={
              showPassword ? (
                <EyeOff size={18} onClick={() => setShowPassword(false)} />
              ) : (
                <Eye size={18} onClick={() => setShowPassword(true)} />
              )
            }
          />
          <ul className="inte-auth__condation">
            <li>
              To create a strong password make sure the password contains:
            </li>
            <ul className="inte-auth__condation--wrapper">
              <li> {checkIcon}</li>
              <li>A minimum of 8 characters</li>
            </ul>
            <ul className="inte-auth__condation--wrapper">
              <li> {checkIcon}</li>
              <li>An uppercase and a lowercase letter</li>
            </ul>
            <ul className="inte-auth__condation--wrapper">
              <li> {checkIcon}</li>
              <li>A number</li>
            </ul>
            <ul className="inte-auth__condation--wrapper">
              <li> {checkIcon}</li>
              <li>One special character</li>
            </ul>
          </ul>

          <TextField
            type={showConfirmPassword ? "text" : "password"}
            placeHolder="Confirm Password"
            suffix={
              showConfirmPassword ? (
                <EyeOff
                  size={18}
                  onClick={() => setShowConfirmPassword(false)}
                />
              ) : (
                <Eye size={18} onClick={() => setShowConfirmPassword(true)} />
              )
            }
          />

          <div className="inte-register__checkBox">
            <Checkbox
              checked={termCondition}
              onChange={() => setTermCondition(!termCondition)}
              label="Accept Terms & Conditions"
            />
            <TextLink
              label="Read Our Policies"
              onClick={() =>
                window.open("https://cedcommerce.com/privacy-policy")
              }
            />
          </div>
        </div>
        <Seprator customClass="inte-auth__bottomSeprator" />
        <Button
          customClass="inte-register__registerButton"
          halign="center"
          content="Create Account"
          isFullWidth
          onClick={() => navigate("/successAccount")}
        />

        <Text as="p" customClass="inte-register__signInButton">
          Already a Member? {"  "}
          <TextLink label="Login" onClick={() => navigate("/login")} />
        </Text>
      </LoginRegister>
    </>
  );
};

export default Register;
