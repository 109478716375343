import { Card, FlexLayout, TextLink } from '@cedcommerce-integration/ounce'
import StatusCard from './StatusCard'
import React from 'react'

const OrderStatus = () => {

  const cardArr = [
    {
      number: 600,
      title: "Awaiting Shipment",
      desc: "Orders",
      color: "yellow"
    },
    {
      number: 500,
      title: "Awaiting Collection",
      desc: "Orders",
      color: "black"
    },
    {
      number: 567,
      title: "Completed",
      desc: "Orders",
      color: "green"
    },
    {
      number: 0,
      title: "Cancelled",
      desc: "Orders",
      color: "red"
    },
  ]

  return (
   
     <Card  title='Order Status' cardType='borderLess' action={<TextLink label="View All" />}>
     <FlexLayout desktopWidth='25' mobileWidth='50' spacing='loose'>
        {
         cardArr.map((item , i) => <React.Fragment key={i}><StatusCard {...item} /></React.Fragment>)
         }
       </FlexLayout>
   </Card>
  )
}

export default OrderStatus