import { Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ViewProduct from "./components/Panel/Products/ViewProduct";
import OrderGrid from "./components/Panel/Orders/OrderGrid";
import Dashboard from "./components/Panel/Dashboard/Dashboard";
import Setting from "./components/Panel/Setting/Setting";
import StepOne from "./components/Onboarding/Stepone";
import ResetLink from "./components/auth/ResetLink";
import ResetPassword from "./components/auth/ResetPassword";
import Panel from "./components/Panel/Panel";
import { useContext } from "react";
import { AuthDataContext } from "./components/utilities/AuthContext/AuthContext";
import CreateCategoryTemplate from "./components/Panel/Category/CreateCategoryTemplate/CreateCategoryTemplate";
import CategoryTemplateListing from "./components/Panel/Category/CategoryTemplateListing/CategoryTemplateListing";
import Help from "./components/Panel/Help/Help";
import Faq from "./components/Panel/Faq/Faq";
import ViewOrder from "./components/Panel/Orders/ViewOrder";
import Onbording2 from "./components/Onboarding2/Onboarding2";
import Onboarding3 from "./components/Onboarding3/Onoarding3";
import Onboarding4 from "./components/OnboardingPricing/Onboarding4";
import Notification from "./components/Notification/Notification";
import Pricing from "./components/Pricing/Pricing";
import EditProduct from "./components/Panel/EditProduct/EditProduct";
import ProductList from "./components/Panel/Products/ProductList";

function App() {
  const { userData } = useContext(AuthDataContext);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/resetlink" element={<ResetLink />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      {userData?.username && (
        <>
          <Route path="/onboarding">
            <Route path="onboarding1" element={<StepOne />} />
            <Route path="onboarding2" element={<Onbording2 />} />
            <Route path="onboarding3" element={<Onboarding3 />} />
            <Route path="onboarding4" element={<Onboarding4 />} />
          </Route>
          <Route path="/" element={<Panel />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/category">
              <Route path="create-category-template" element={<CreateCategoryTemplate />} />
              <Route path="category-template-listing" element={<CategoryTemplateListing />} />
            </Route>
            <Route path="/product">
              <Route path="productlist/viewProduct" element={<ViewProduct />} />
              <Route path="productlist" element={<ProductList />} />
              <Route path="productlist/editProduct" element={<EditProduct />} />
            </Route>
            <Route path="/help" element={<Help />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/orders">
              <Route path="orderlist" element={<OrderGrid />} />
              <Route path="orderlist/vieworder" element={<ViewOrder />} />
            </Route>

            <Route path="/configuration" element={<Setting />} />
          </Route>
        </>
      )}
    </Routes>
  );
}

export default App;
