import React, { useEffect, useState } from "react";
import "./CustomTable.css";
import {
  Badge,
  FlexLayout,
  Text,
  Thumbnail,
} from "@cedcommerce-integration/ounce";

const CustomTable = (id: any) => {
  const [totalPrice, setTotalPrice] = useState(0);

  const [tableDataRow, setTableRowData] = useState([]);

  useEffect(() => {
    let holdData: any = [];
    fetch(`https://dummyjson.com/products/${id.id}`)
      .then((res) => res.json())
      .then((json) => {
        json?.images?.map((imgUrl: string, index: number) => {
          return (holdData = [
            ...holdData,
            {
              id: index,
              key: `${index}`,
              image: imgUrl,
              name: json.title,
              quantity: json.stock,
              price: json.price,
            },
          ]);
        });
        setTableRowData(holdData);
      });
  }, []);

  return (
    <>
      <FlexLayout direction="vertical" spacing="mediumLoose">
        <Text type="T-7" fontweight="bold">
          Order Details
        </Text>

        <div className="inte-customTable">
          {tableDataRow.map((item: any) => {
            return (
              <div className="inte-customTable__items">
                <FlexLayout spacing="loose">
                  <Thumbnail src={item.image} />
                  <FlexLayout direction="vertical" spacing="extraTight">
                    <Text type="T-7" fontweight="bold">
                      {item.name}
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      Quantity: {item.quantity}unit
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      SKU: 215454642
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      Qty: {item.quantity} unit
                    </Text>
                    <Text type="T-7" textcolor="secondary">
                      Price: ${item.price}
                    </Text>
                  </FlexLayout>
                </FlexLayout>
              </div>
            );
          })}
          <div className="inte-viewProduct">
            <div className="inte-viewProduct__totalCount">
              <FlexLayout direction="vertical" spacing="tight">
                <Text type="T-7" fontweight="bold">
                  Order Sub total
                </Text>
                <Text type="T-7" fontweight="bold">
                  Shipping Charges
                </Text>
                <Text type="T-7" fontweight="bold">
                  Discount
                </Text>
                <Text type="T-7" fontweight="bold">
                  Estimated Tax
                </Text>
              </FlexLayout>
              <FlexLayout direction="vertical" spacing="tight">
                <Text type="T-7">$600</Text>
                <Text type="T-7">$10</Text>
                <Text type="T-7">$0</Text>
                <Text type="T-7">$15</Text>
              </FlexLayout>
            </div>
            <div className="inte-viewProduct__footer">
              <Text type="T-7" fontweight="bold">
                Total
              </Text>

              <Text type="T-7">${totalPrice}</Text>
            </div>
          </div>
        </div>
      </FlexLayout>
    </>
  );
};

export default CustomTable;
