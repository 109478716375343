import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  FlexChild,
  FlexLayout,
  List,
  RadioGroup,
  Select,
  Seprator,
  Text,
  TextLink,
  ToolTip,
  useMobileDevice,
} from "@cedcommerce-integration/ounce";
import { CheckCircle } from "@cedcommerce-integration/ounce/dist/icons";
import React, { useState } from "react";

const AssignProduct = () => {
  const [overrideListing, setOverrideListing] = useState(false);
  const [radioGroupValue, setRadioGroupValue] = useState<string | number>();

  const isMobile = useMobileDevice();

  return (
    <Card cardType="bordered">
      <FlexLayout spacing="mediumLoose">
        <FlexChild desktopWidth="33" mobileWidth="100" tabWidth="100">
          <FlexLayout spacing="tight" direction="vertical">
            <FlexLayout spacing="extraTight" direction="vertical">
              <ToolTip
                direction="top"
                activator={
                  <Text
                    customClass="inte-underlineText--dashed"
                    as={"h3"}
                    fontweight="bold"
                    type="T-7"
                  >
                    Assign Product to the Template
                  </Text>
                }
                helpText={
                  <>
                    <Text
                      customClass={isMobile ? "" : "inte-text-whiteCol"}
                      type="T-7"
                    >
                      Select{" "}
                      <Text
                        customClass={isMobile ? "" : "inte-text-whiteCol"}
                        as={"span"}
                        type="T-7"
                        fontweight="bold"
                      >
                        {" "}
                        Any Condition
                      </Text>{" "}
                      to fetch the product(s) fulfilling any of the conditions
                      created.
                    </Text>
                    <Text
                      customClass={isMobile ? "" : "inte-text-whiteCol"}
                      type="T-7"
                    >
                      Select{" "}
                      <Text
                        customClass={isMobile ? "" : "inte-text-whiteCol"}
                        as={"span"}
                        type="T-7"
                        fontweight="bold"
                      >
                        {" "}
                        All Condition
                      </Text>{" "}
                      to fetch the product(s) fulfilling all the conditions
                      created.
                    </Text>
                    <Text
                      customClass={isMobile ? "" : "inte-text-whiteCol"}
                      type="T-7"
                    >
                      Click on{" "}
                      <Text
                        customClass={isMobile ? "" : "inte-text-whiteCol"}
                        as={"span"}
                        type="T-7"
                        fontweight="bold"
                      >
                        {" "}
                        Run Query
                      </Text>{" "}
                      to fetch the number of the product(s) on the basis of the
                      Rule Group(s) created.
                    </Text>
                  </>
                }
              />
              <Text type="T-7">
                Create Rule Group(s) (query) to fetch a particular set of the
                product(s) in the current profile.
              </Text>
            </FlexLayout>
            <List type="disc">
              <Text type="T-7">
                Select{" "}
                <Text as={"span"} type="T-7" fontweight="bold">
                  {" "}
                  Any Condition
                </Text>{" "}
                to fetch the product(s) fulfilling any of the conditions
                created.
              </Text>
              <Text type="T-7">
                Select{" "}
                <Text as={"span"} type="T-7" fontweight="bold">
                  {" "}
                  All Condition
                </Text>{" "}
                to fetch the product(s) fulfilling all the conditions created.
              </Text>
              <Text type="T-7">
                Click on{" "}
                <Text as={"span"} type="T-7" fontweight="bold">
                  {" "}
                  Run Query
                </Text>{" "}
                to fetch the number of the product(s) on the basis of the Rule
                Group(s) created.
              </Text>
            </List>
          </FlexLayout>
        </FlexChild>
        <FlexChild desktopWidth="66"  mobileWidth="100" tabWidth="100">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <Checkbox
              checked={overrideListing}
              onChange={(newValue) => setOverrideListing(newValue)}
              label="Override Listings"
              description="Select this option if you wish to override listings with templates already assigned."
            />
            <Seprator />
            <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
              <FlexLayout
                spacing="extraTight"
                direction="vertical"
                wrap="noWrap"
              >
                <Text as={"h3"} type="T-7" fontweight="bold">
                  Rule Group
                </Text>
                <Text type="T-7">Product Must Match</Text>
              </FlexLayout>
              <RadioGroup
                value={radioGroupValue}
                onChange={(newVal) => setRadioGroupValue(newVal)}
                direction="horizontal"
                options={[
                  {
                    label: "Any Condition",
                    value: "any",
                  },
                  {
                    label: "All Condition",
                    value: "all",
                  },
                ]}
              />
            </FlexLayout>
            <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="tight">
                <FlexChild mobileWidth="100" tabWidth="100" desktopWidth="33">
                  <Select
                    options={[{ label: "Price", value: "price" },{ label: "Brand", value: "Brand" },{ label: "Category", value: "Category" }]}
                    value={""}
                  />
                </FlexChild>
                <FlexChild mobileWidth="100" tabWidth="100" desktopWidth="33">
                  <Select
                    options={[{ label: "Equal", value: "Equal" },{ label: "Not Equal", value: "Not Equal" }]}
                    value={""}
                  />
                </FlexChild>
                <FlexChild mobileWidth="100" tabWidth="100" desktopWidth="33">
                  <Select
                     options={[{ label: "Price", value: "price" },{ label: "Brand", value: "Brand" },{ label: "Category", value: "Category" }]}
                    value={""}
                  />
                </FlexChild>
              </FlexLayout>
              <FlexLayout halign="end" valign="center" spacing="tight">
                <Button type="textButton">Add New Rule</Button>
                <Button type="outlined">Run Query</Button>
              </FlexLayout>
            </FlexLayout>
            <FlexLayout spacing="mediumTight">
              <Text type="T-7">Applied Rule :</Text>
              <Badge size="medium" type="secondary" variant="accent">
                ( Category Equals Shirt )
              </Badge>
            </FlexLayout>
            <Alert
              type="success"
              hasDestroy
              icon={<CheckCircle size={20} />}
              title={
                <FlexLayout>
                  <Text type="T-7">6 product(s) found under this query.</Text>
                  <TextLink label="View Sample Products(s)" />
                </FlexLayout>
              }
            />
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
    </Card>
  );
};

export default AssignProduct;
