import {
  Card,
  FlexChild,
  FlexLayout,
  RadioGroup,
  Select,
  TextField,
  TextLink,
  ToggleGroup,
  useMobileDevice,
} from "@cedcommerce-integration/ounce";
import React, { useState } from "react";

import { ExternalLink } from "@cedcommerce-integration/ounce/dist/icons";
const ProductSetting = () => {
  const [selectValue, setSelectValue] = useState("none");

  const [toggleGroupValue, setToggleGrouValue] = useState<string[]>([]);

  const [radioGroupValue, setRadioGrouValue] = useState<string | number>();

  const isMobile = useMobileDevice();

  return (
    <FlexLayout direction="vertical" spacing="mediumLoose">
      <Card
        cardType={isMobile ? undefined : "bordered"}
        title="Product Custom Pricing"
        subTitle="Enable to automatically delete from TikTok Shop Shop when product(s) is deleted from Shopify Store. Individual variants won’t be deleted"
      >
        <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
          <Select
            options={[
              {
                label: "None",
                value: "none",
              },
              {
                label: "item1",
                value: "Item 1",
              },
              {
                label: "item2",
                value: "Item 2",
              },
            ]}
            value={selectValue}
            onChange={(e) => setSelectValue(e)}
          />
          <ToggleGroup
            value={toggleGroupValue}
            onChange={(newValue) => setToggleGrouValue(newValue)}
            options={[
              {
                label: "Sync Product",
                value: "syncPro",
                helpText: "Sync your products",
              },
              {
                label: "Sync Price",
                value: "syncPri",
                helpText: "Sync your price",
              },
              {
                label: "Sync Product",
                value: "syncPro1",
                helpText: "Sync your products",
              },
              {
                label: "Create order Automatically",
                value: "crePro",
                helpText: "This will allow your order create automatically",
              },
              {
                label: "Sync order shipment",
                value: "syncShip",
                helpText: "Sync your order shipment",
              },
              {
                label: "Blank Box",
                value: "blankBox",
              },
              {
                label: "Sync Product",
                value: "syncPro2",
              },
            ]}
          />
        </FlexLayout>
      </Card>
      <Card
        cardType={isMobile ? undefined : "bordered"}
        title="Product Custom Pricing"
        action={
          <TextLink
            label={"Click here to learn more"}
            icon={<ExternalLink size={16} />}
          />
        }
      >
        <FlexLayout spacing="loose" valign="center">
          <RadioGroup
            direction="horizontal"
            onChange={(newValue) => setRadioGrouValue(newValue)}
            value={radioGroupValue}
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
          />
          <FlexChild childWidth="fullWidth">
            <Select
              options={[
                {
                  label: "Brand or manufacturer does not provide a GTIN",
                  value: "Brand or manufacturer does not provide a GTIN",
                },
              ]}
              value={"Brand or manufacturer does not provide a GTIN"}
            />
          </FlexChild>
        </FlexLayout>
      </Card>
      <Card
        cardType={isMobile ? undefined : "bordered"}
        title="Set Aside Inventory"
        action={
          <TextLink
            label={"Click here to learn more"}
            icon={<ExternalLink size={16} />}
          />
        }
        subTitle="Set aside specific quantities of inventory for Amazon MCF and transfer lower/remaining quantities to Mirakl."
      >
        <TextField
          placeHolder="00"
          helpText="For example : If you choose to reserve 5 inventory values out of 20, we will send 15 Amazon MCF inventory on Mirakl."
        />
      </Card>
    </FlexLayout>
  );
};

export default ProductSetting;
