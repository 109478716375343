import { Card, Switcher } from '@cedcommerce-integration/ounce'
import React, { useState } from 'react'
import BarChart from '../Chart/BarChart'

const AnalyticsChart = () => {

  const chartData = [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3];
  const chartLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [switcherValue, setSwitcherValue] = useState({ label: "Yearly" })

  return (
    <Card
      cardType='bordered'
      title='Analytics'
      subTitle='Income and Expences'
      action={(
        <Switcher
          onChange={(newValue) => setSwitcherValue(newValue)}
          options={[
            { label: "Yearly" }, { label: "Monthly" }, { label: "Weekely" }
          ]}
          value={switcherValue}
        />
      )}
    >
      <BarChart
        data={chartData}
        labels={chartLabels}

      />
    </Card>
  )
}

export default AnalyticsChart