import React, { useContext, useState } from "react";
import {
  Seprator,
  TextField,
  Text,
  Button,
  TextLink,
  useToast,
} from "@cedcommerce-integration/ounce";
import { EyeOff, Eye } from "@cedcommerce-integration/ounce/dist/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginRegister from "./LoginRegister/LoginRegister";
import "./Common.css";
import "../auth/LoginRegister/LoginRegister.css";
import { AuthDataContext } from "../utilities/AuthContext/AuthContext";

const Login = () => {
  const showToast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { addUser } = useContext(AuthDataContext);

  const [textValue, setTextValue] = useState({
    username: "admin@gmail.com",
    password: "admin",
  });
  const handleOnchange = (fieldName: string, value: any) => {
    setTextValue((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const checkValidation = () => {
    if (
      textValue.username === "admin@gmail.com" &&
      textValue.password === "admin"
    ) {
      addUser(textValue.username, textValue.password);
      showToast({
        message: "Login successfull",
        type: "success",
      });
      navigate("/dashboard");
    } else {
      showToast({
        message: "Please provide valid email and password",
        type: "error",
      });
    }
  };
  return (
    <>
      <LoginRegister>
        <Text
          customClass="inte-auth_userHeading"
          fontweight="bold"
          type="T-4"
          as="h2"
        >
          Login
        </Text>
        <Seprator customClass="inte-auth_headerDevider" />

        <div className="inte-auth__wrapper">
          <TextField
            placeHolder="Enter :-  admin@gmail.com"
            onChange={(e) => handleOnchange("userName", e)}
            value={textValue.username}
          />
          <TextField
            onChange={(e) => handleOnchange("password", e)}
            onEnter={checkValidation}
            value={textValue.password}
            type={showPassword ? "text" : "password"}
            placeHolder="Enter :-  admin"
            suffix={
              showPassword ? (
                <EyeOff size={18} onClick={() => setShowPassword(false)} />
              ) : (
                <Eye size={18} onClick={() => setShowPassword(true)} />
              )
            }
          />
          <TextLink
            customClass="inte-register__forgotButton"
            label="Forgot Password?"
            onClick={() => navigate("/resetlink")}
          />
        </div>
        <Seprator customClass="inte-auth__bottomSeprator" />
        <Button
          customClass="inte-register__registerButton"
          halign="center"
          content="Login"
          isFullWidth
          onClick={checkValidation}
        />

        <Text as="p" customClass="inte-register__signInButton">
          Don’t have an account? {"  "}
          <Routes>
            <Route
              path="*"
              element={
                <TextLink
                  label="Register"
                  onClick={() => navigate("/register")}
                />
              }
            />
          </Routes>
        </Text>
      </LoginRegister>
    </>
  );
};

export default Login;
