import {
  Badge,
  Button,
  Card,
  Checkbox,
  FlexChild,
  FlexLayout,
  Seprator,
  StepWizard,
  Text,
  TextLink,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import {  Check } from "@cedcommerce-integration/ounce/dist/icons";
import "../Onboarding/Onboard.css";

import { useState } from "react";
import ConnectWalmart from "./ConnectWalmart";
import OnboardGuide from "./OnboardingGuide";
import { Logo } from "../Onboarding/icons";

function Onbording2() {
  const { width } = useWindowResize();
  const [value, setValue] = useState(true);
  const [step, setStep] = useState(1);
  return (
    <>
      <FlexLayout desktopWidth="66" valign="center" direction="vertical">
        <Card customClass="pt40" cardType="borderLess">
          <FlexLayout wrap="noWrap" spacing="loose" customClass="mb-36">
            {Logo}
            <FlexChild>
              <FlexLayout direction="vertical">
                <Text type="T-4" fontweight="bolder">
                  Welcome to TikTok Shop Integration
                </Text>
                <Text type="T-4" textcolor="secondary">
                  {" "}
                  By CedCommerce
                </Text>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
          <FlexLayout spacing="tight" direction="vertical">
            <StepWizard
              currentStep={step}
              direction="horizontal"
              onChange={(newStep) => {
                // setStep(newStep)
              }}
              size="small"
              steps={[
                {
                  label: "Connect (App Name)",
                  value: "Step1",
                },
                {
                  label: "Connect (App Name) Shop",
                  value: "Step2",
                },
                {
                  label: "Configuration",
                  value: "Step3",
                },
              ]}
            />

            {step === 1 && (
              <Card
                cardType="bordered"
                title="How to generate API credentials from your Shopify store"
              >
                <FlexLayout direction="vertical" spacing="mediumLoose">
                  <Seprator />
                  <FlexLayout direction="vertical" spacing="tight">
                    <FlexLayout wrapMob="noWrap" spacing="tight">
                     <Badge size="small" icon={<Check size={16}/>} type="success"/>
                        <FlexLayout>
                        <Text type="T-7" textcolor="secondary">
                          Click the{" "}  </Text>
                          <Text
                            type="T-7"
                            children="Open Store"
                            fontweight="bold"
                          />{" "}
                          <Text type="T-7">option on your{" "}</Text>
                          <TextLink label=" Shopify Store Admin" />
                        </FlexLayout>
                    
                    </FlexLayout>

                    <FlexLayout wrapMob="noWrap" spacing="tight">
                    <Badge size="small" icon={<Check size={16}/>} type="success"/>
                      <Text type="T-7" fontweight="bold">
                        {`Apps > Apps & Sales Channel settings > Develop apps > Create App`}
                      </Text>
                    </FlexLayout>

                    <FlexLayout wrap="noWrap" spacing="tight">
                    <Badge size="small" icon={<Check size={16}/>} type="success"/>
                      <>
                        <FlexLayout>
                          <Text type="T-7">Click</Text>{" "}
                          <Text
                            type="T-7"
                            children="Configure Admin API Scopes"
                            fontweight="bold"
                          />{" "}
                          <Text
                            type="T-7"
                            children="to select the following scopes -"
                          />
                        </FlexLayout>

                        <FlexLayout spacing="mediumLoose">
                          <Text
                            type="T-7"
                            textcolor="secondary"
                            children="read_products"
                          />
                          <Text
                            type="T-7"
                            textcolor="secondary"
                            children="write_orders"
                          />
                          <Text
                            type="T-7"
                            textcolor="secondary"
                            children="write_resource_feedbacks"
                          />
                          <Text
                            type="T-7"
                            textcolor="secondary"
                            children="read_locations"
                          />
                          <Text
                            type="T-7"
                            textcolor="secondary"
                            children="read_inventory"
                          />
                          <Text
                            type="T-7"
                            textcolor="secondary"
                            children="read_fulfillments "
                          />
                        </FlexLayout>
                      </>
                    </FlexLayout>

                    <FlexLayout wrapMob="noWrap" spacing="tight">
                    <Badge size="small" icon={<Check size={16}/>} type="success"/>
                      <FlexLayout>
                        <Text type="T-7" children="Save scopes and click" />{" "}
                        <Text
                          type="T-7"
                          children="Install App."
                          fontweight="bold"
                        />{" "}
                        <TextLink label="See Guide" />
                      </FlexLayout>
                    </FlexLayout>
                  </FlexLayout>
                  <Seprator />
                  <FlexLayout halign="fill" valign="center" >
                    <Checkbox
                      checked={value}
                      label={
                        <FlexLayout valign="center">
                          <Text type="T-7" children="Accept" />
                          <TextLink label="privacy policy" />
                          <Text type="T-7" children="of CedCommerce" />
                        </FlexLayout>
                      }
                      onChange={() => {
                        setValue(!value);
                      }}
                    />
                    {width >= 768 && (
                      <Button content="Connect " onClick={() => setStep(2)} />
                    )}
                  </FlexLayout>
                  {width < 768 && (
                    <Button
                      isFullWidth
                      halign="center"
                      content="Connect "
                      onClick={() => setStep(2)}
                    />
                  )}
                </FlexLayout>
              </Card>
            )}
            {step === 2 && <ConnectWalmart onStepChange={() => setStep(3)} />}
            {step === 3 && <OnboardGuide />}
          </FlexLayout>
        </Card>
      </FlexLayout>
    </>
  );
}

export default Onbording2;
