import {
  Accordion,
  Card,
  FlexLayout,
  PageHeader,
  Tabs,
  TextField,
  TextLink,
} from "@cedcommerce-integration/ounce";
import "./Pricing.css";
import SlideAdded from "./Slide";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Search } from "@cedcommerce-integration/ounce/dist/icons";

function Pricing() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string | number>("faq");
  const [selectTab, setSelectTab] = useState<string | number>("regular");
  const [activeAccordian, setActiveAccordian] = useState<string>();

  return (
    <>
      <PageHeader
        title="Prcing"
        description="Avail the free trial for the application and choose the best suited pricing plan for requirement"
      />
      <Tabs
      spacing="mediumLoose"
        tabs={[
          {
            key: "regular",
            label: "Regular Plan",
          },
          {
            key: "business",
            label: "Business Plan",
          },
        ]}
        value={selectTab}
        onChange={(newTabKey) => setSelectTab(newTabKey)}
      >
        <FlexLayout
          direction="vertical"
          desktopWidth="100"
          mobileWidth="100"
          tabWidth="100"
          spacing="mediumLoose"
        >
          {selectTab==="regular"?<SlideAdded />:<SlideAdded />}
          <Card
            cardType="bordered"
            title="FAQs"
            action={
              <TextLink
                onClick={() => navigate("/faq")}
                label="View all FAQs"
              />
            }
          >
            <FlexLayout direction="vertical" spacing="mediumLoose">
              <TextField
              prefix={<Search size={20}/>}
              />
              <Tabs

                tabs={[
                  {
                    key: "faq",
                    label: "Amazon",
                  },
                  {
                    key: "ebay",
                    label: "Ebay",
                  },
                  {
                    key: "video",
                    label: "Walmart",
                  },
                ]}
               spacing="mediumLoose"
                value={selectedTab}
                onChange={(newTabKey) => setSelectedTab(newTabKey)}
                children={
                  selectedTab === "faq" ? (
                    <>
                      <Accordion
                        children={
                          "Error found in 20 products post product upload."
                        }
                        title="Error found in 20 products post product upload."
                        isActive={activeAccordian === "Error1"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error1" ? "" : "Error1"
                          )
                        }
                      />
                      <Accordion
                        children={"Failed to create 200 orders on Shopify."}
                        title="Failed to create 200 orders on Shopify."
                        isActive={activeAccordian === "Error2"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error2" ? "" : "Error2"
                          )
                        }
                      />
                      <Accordion
                        children={
                          "Error found in 20 products post product upload."
                        }
                        title="Error found in 20 products post product upload."
                        isActive={activeAccordian === "Error3"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error3" ? "" : "Error3"
                          )
                        }
                      />
                      <Accordion
                        children={"Failed to create 200 orders on Shopify."}
                        title="Failed to create 200 orders on Shopify."
                        customClass="without-border-bottom-accordian"
                        isActive={activeAccordian === "Error4"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error4" ? "" : "Error4"
                          )
                        }
                      />
                    </>
                  ) : selectedTab === "ebay" ? (
                    <>
                      <Accordion
                        children={
                          "Error found in 20 products of Walmart post product upload."
                        }
                        title="Error found in 20 products of Walmart post product upload."
                        isActive={activeAccordian === "Error1"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error1" ? "" : "Error1"
                          )
                        }
                      />
                      <Accordion
                        children={"Failed to create 200 orders on Ebay."}
                        title="Failed to create orders on Shopify."
                        isActive={activeAccordian === "Error2"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error2" ? "" : "Error2"
                          )
                        }
                      />
                      <Accordion
                        children={
                          "Error found in 20 products post product upload."
                        }
                        title="Error found in 20 products post product upload."
                        isActive={activeAccordian === "Error3"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error3" ? "" : "Error3"
                          )
                        }
                      />
                      <Accordion
                        children={"Failed to create 200 orders on Shopify."}
                        title="Failed to create 200 orders on Shopify."
                        customClass="without-border-bottom-accordian"
                        isActive={activeAccordian === "Error4"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error4" ? "" : "Error4"
                          )
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Accordion
                        children={
                          "Error found in 20 Walmart products post product upload."
                        }
                        title="Error found in 20 products post product upload."
                        isActive={activeAccordian === "Error1"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error1" ? "" : "Error1"
                          )
                        }
                      />
                      <Accordion
                        children={"Failed to create 200 orders on Shopify."}
                        title="Failed to create 200 orders on Shopify."
                        isActive={activeAccordian === "Error2"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error2" ? "" : "Error2"
                          )
                        }
                      />
                      <Accordion
                        children={
                          "Error found in 20 products post product upload."
                        }
                        title="Error found in 20 products post product upload."
                        isActive={activeAccordian === "Error3"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error3" ? "" : "Error3"
                          )
                        }
                      />
                      <Accordion
                        children={"Failed to create 200 orders on Shopify."}
                        title="Failed to create 200 orders on Shopify."
                        customClass="without-border-bottom-accordian"
                        isActive={activeAccordian === "Error4"}
                        onClick={() =>
                          setActiveAccordian(
                            activeAccordian === "Error4" ? "" : "Error4"
                          )
                        }
                      />
                    </>
                  )
                }
              />
            </FlexLayout>
          </Card>
        </FlexLayout>
      </Tabs>
    </>
  );
}
export default Pricing;
