import { Card, FlexLayout, Select, Text } from "@cedcommerce-integration/ounce";
import { RefreshCcw } from "@cedcommerce-integration/ounce/dist/icons";

const Shipping = () => {
  return (
    <Card
      primaryAction={{
        content: "Add",
        type: "primary",
      }}
      cardType="bordered"
      secondaryAction={{
        content: "Sync Shipping Carriers",
        icon: <RefreshCcw />,
        type: "outlined",
      }}
    >
      <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            Shipping Carrier Mapping
          </Text>
          <Text type="T-7" textcolor="secondary">
            This setting will help streamline order fulfillment from the Shopify
            store to TikTok Shop.
          </Text>
        </FlexLayout>
        <FlexLayout direction="vertical" wrap="noWrap" spacing="extraLoose">
          <FlexLayout spacing="loose" childWidth="fullWidth">
            <Select
              placeholder="Select"
              label="Label"
              value={""}
              options={[{ label: "select", value: "select" },{ label: "item 1", value: "select" },{ label: "item 2", value: "select" }]}
            />
            <Select
              placeholder="Select"
              label="Label"
              value={""}
              options={[{ label: "select", value: "select" },{ label: "item 1", value: "select" },{ label: "item 2", value: "select" }]}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export default Shipping;
