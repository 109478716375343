import {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  FlexChild,
  FlexLayout,
  Select,
  Seprator,
  Text,
  useMobileDevice,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import {

  Play,
  ArrowRight,
  Check,
} from "@cedcommerce-integration/ounce/dist/icons";
import { useState } from "react";
import { useNavigate } from "react-router";

function CreateDefault2() {
  const { width } = useWindowResize();
  const isMobile = useMobileDevice();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [activeAccordian, setActiveAccordian] = useState<number>(0);
  const accordianChildren = !isMobile ? (
    <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
      <FlexLayout spacing="tight" childWidth="fullWidth">
        <FlexChild desktopWidth="50">
          <Text type="T-7" fontweight="bold">
            TikTok Shop Attribute
          </Text>
        </FlexChild>
        <FlexChild desktopWidth="50">
          <Text type="T-7" fontweight="bold">
            BigCommerce Attribute
          </Text>
        </FlexChild>
      </FlexLayout>
      <Text type="T-7">Subheading content</Text>
      <FlexLayout spacing="tight" childWidth="fullWidth">
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text type="T-7" as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexChild>
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={[{ label: "Select", value: "se" }]}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
      <Seprator />
      <FlexLayout spacing="tight" childWidth="fullWidth">
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text type="T-7" as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexChild>
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
            isDisabled
              options={[{ label: "Select", value: "se" }]}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
    </FlexLayout>
  ) : (
    <FlexLayout direction="vertical" wrap="noWrap" spacing="mediumLoose">
      <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            TikTok Shop Attribute
          </Text>
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexLayout>
        <Seprator type="dashed" />
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            BigCommerce Attribute
          </Text>
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={[{ label: "Select", value: "se" }]}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <Seprator />
      <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            TikTok Shop Attribute
          </Text>
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexLayout>
        <Seprator type="dashed" />
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            BigCommerce Attribute
          </Text>
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={[{ label: "Select", value: "se" }]}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
  return (
    <Card
      cardType="bordered"
      title="Create Default Template"
      spacing="loose"
      subTitle="Default template to upload product(s) on ( Shop Name)"
      action={
        width < 768 ? (
          <Button type="outlined" icon={<Play size={20} />} />
        ) : (
          <Button type="outlined" content="View Guide" icon={<Play />} />
        )
      }
    >
      <FlexLayout direction="vertical" spacing="mediumLoose">
        <Alert
          title="Based on the selected Category you will further map BigCommerce attribue with tiktok attribute"
          type="info"
        />
        <FlexChild>
          <FlexLayout direction="vertical">
            <Text fontweight="bold" type="T-7">
              Select Product Category
            </Text>
            <Text textcolor="secondary">
              Default template to upload product(s) on TikTok Shop
            </Text>
          </FlexLayout>
        </FlexChild>
        <Select
          options={[
            {
              label: "Length",
              value: "length",
            },
            {
              label: "Width",
              value: "width",
            },
          ]}
          value={value}
          placeholder={"Select Category"}
          onChange={(e) => {
            setValue(e);
          }}
        />
        <Seprator />

        <Text fontweight="bold" type="T-7">
          Select Attribute Mapping
          <Text type="T-7" as="span" textcolor="negative">
            *
          </Text>
        </Text>

        <Text textcolor="secondary">
          Through attribute mapping you can enhance your listing catalog with
          additional listing information.
        </Text>
        
 
        <Accordion
        customClass="border-btm"
        onClick={() => setActiveAccordian((prev) => (prev === 0 ? -1 : 0))}
        isActive={activeAccordian === 0}
        title="Map Attribute"
        children={accordianChildren}
      />
      <Accordion
       customClass="border-btm"
        onClick={() => setActiveAccordian((prev) => (prev === 1 ? -1 : 1))}
        isActive={activeAccordian === 1}
        title="Variation Attribute"
        children={accordianChildren}
      />
      
        <Seprator/>
        <FlexLayout halign="end" childWidth={width > 768 ? "none" : "fullWidth"}>
        <Button
          content="Next"
          isFullWidth={width > 768 ? false : true}
          halign="center"
          icon={<ArrowRight />}
          iconAlign="right"
          onClick={() => navigate("/dashboard")}
        />
      </FlexLayout>
      </FlexLayout>

    
     
    
    
    </Card>
  );
}
export default CreateDefault2;
