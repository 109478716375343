import {
  Badge,
  DataTable,
  FlexLayout,
  Text,
  Thumbnail,
} from "@cedcommerce-integration/ounce";
import React, { useEffect, useState } from "react";

const Table = (id: any) => {
  const [totalPrice, setTotalPrice] = useState(0);

  const [tableDataRow, setTableRowData] = useState([
    {
      id: 1,
      key: "1",
      name: "",
      quantity: "",
      price: "",
    },
  ]);

  useEffect(() => {
    let holdData: any = [];
    fetch(`https://dummyjson.com/products/${id.id}`)
      .then((res) => res.json())
      .then((json) => {
        json?.images?.map((imgUrl: string, index: number) => {
          setTotalPrice(json.price * (index + 1));
          return (holdData = [
            ...holdData,
            {
              id: index,
              key: `${index}`,
              image: (
                <Thumbnail
                  size="medium"
                  alt="Image Data Not Found"
                  src={imgUrl}
                />
              ),
              name: json.title,
              quantity: <Text type="T-7">{json.stock}unit</Text>,
              price: <Text type="T-7">${json.price}</Text>,
            },
          ]);
        });
        setTableRowData(holdData);
      });
  }, []);

  return (
    <FlexLayout direction="vertical" spacing="mediumLoose">
      <FlexLayout halign="fill">
        <Text type="T-7" fontweight="bold">
          Order Details
        </Text>
        <FlexLayout spacing="mediumTight">
          <Text type="T-7">Fulfilled By:</Text>

          <Badge type="secondary" variant="accent">
            Fulfilled By Merchant
          </Badge>
        </FlexLayout>
      </FlexLayout>

      <DataTable
        dataSource={tableDataRow}
        columns={[
          {
            dataIndex: "image",
            key: "image",
            width: 80,
            title: "Image",
          },
          {
            dataIndex: "name",
            key: "name",
            title: "Name",
          },
          {
            dataIndex: "quantity",
            key: "quantity",
            title: "Quantity",
          },
          {
            dataIndex: "price",
            key: "price",
            fixed: "right",
            title: "Price",
            width: 150,
          },
        ]}
      />

      <div className="inte-viewProduct">
        <div className="inte-viewProduct__totalCount">
          <FlexLayout direction="vertical" spacing="tight">
            <Text type="T-7" fontweight="bold">
              Order Sub total
            </Text>
            <Text type="T-7" fontweight="bold">
              Shipping Charges
            </Text>
            <Text type="T-7" fontweight="bold">
              Discount
            </Text>
            <Text type="T-7" fontweight="bold">
              Estimated Tax
            </Text>
          </FlexLayout>
          <FlexLayout direction="vertical" spacing="tight">
            <Text type="T-7">$600</Text>
            <Text type="T-7">$10</Text>
            <Text type="T-7">$0</Text>
            <Text type="T-7">$15</Text>
          </FlexLayout>
        </div>
        <div className="inte-viewProduct__footer">
          <Text type="T-7" fontweight="bold">
            Total
          </Text>

          <Text type="T-7">${totalPrice}</Text>
        </div>
      </div>
    </FlexLayout>
  );
};

export default Table;
