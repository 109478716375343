import React from "react";
import "./StatusCard.css";
import { Card, Text } from "@cedcommerce-integration/ounce";
import { ArrowRight } from "@cedcommerce-integration/ounce/dist/icons";

interface StatusCardProps {
  number: number;
  color: string;
  title: string;
  desc: string;
}

const StatusCard: React.FC<StatusCardProps> = (props) => {
  return (
    <Card
      cardType="bordered"
      customClass={`inte-statusCard inte-statusCard--${props.color}`}
      title={props.number.toString()}
      onClick={() => {}}
      action={<ArrowRight size={20} />}
    >
      <Text type="T-7" wordBreak="breakAll" fontweight="bold">
        {props.title}
      </Text>
      <Text textcolor="secondary" type="T-8">
        {props.desc}
      </Text>
    </Card>
  );
};

export default StatusCard;
