import { Accordion, Badge, Card, Tabs } from "@cedcommerce-integration/ounce";
import { TabI } from "@cedcommerce-integration/ounce/dist/components/Tabs/Tabs";
import React, { useState } from "react";
import {
  AlertCircle,
  AlertOctagon,
  Bell,
} from "@cedcommerce-integration/ounce/dist/icons";

const AttentionRequired = () => {
  const [selectedTab, setSelectedTab] = useState<string | number>("errors");

  const [activeAccordian, setActiveAccordian] = useState<string>("");
  const tabs2: TabI[] = [
    {
      label: "Errors",
      key: "errors",
      badge: <Badge children={2} type="error" />,
    },
    {
      label: "Alerts",
      key: "alert",
      badge: <Badge children={6} type="warning" />,
    },
    {
      label: "Announcements",
      key: "announcement",
      badge: <Badge children={1} type="primary" />,
    },
  ];

  const tabContent: { [key: string]: JSX.Element } = {
    errors: (
      <>
        <Accordion
          children={"Error found in 20 products post product upload."}
          title="Error found in 20 products post product upload."
          icon={<AlertOctagon color="var(--inte-R300)" size={20} />}
          isActive={activeAccordian === "Error"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "Error" ? "" : "Error")
          }
        />
        <Accordion
          children={"Failed to create 200 orders on Shopify."}
          title="Failed to create 200 orders on Shopify."
          icon={<AlertOctagon color="var(--inte-R300)" size={20} />}
          isActive={activeAccordian === "create"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "create" ? "" : "create")
          }
        />
      </>
    ),
    alert: (
      <>
        <Accordion
          children={"Error found in 20 products post product upload."}
          title="Error found in 20 products post product upload."
          icon={<AlertCircle size={20} color="var(--inte-Y300)" />}
          isActive={activeAccordian === "Error1"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "Error1" ? "" : "Error1")
          }
        />
        <Accordion
          children={"Failed to create 200 orders on Shopify."}
          title="Failed to create 200 orders on Shopify."
          icon={<AlertCircle size={20} color="var(--inte-Y300)" />}
          isActive={activeAccordian === "create1"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "create1" ? "" : "create1")
          }
        />
        <Accordion
          children={"Error found in 20 products post product upload."}
          title="Error found in 20 products post product upload."
          icon={<AlertCircle size={20} color="var(--inte-Y300)" />}
          isActive={activeAccordian === "Error2"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "Error2" ? "" : "Error2")
          }
        />
        <Accordion
          children={"Failed to create 200 orders on Shopify."}
          title="Failed to create 200 orders on Shopify."
          icon={<AlertCircle size={20} color="var(--inte-Y300)" />}
          isActive={activeAccordian === "create2"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "create2" ? "" : "create2")
          }
        />
        <Accordion
          children={"Error found in 20 products post product upload."}
          title="Error found in 20 products post product upload."
          icon={<AlertCircle size={20} color="var(--inte-Y300)" />}
          isActive={activeAccordian === "Error3"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "Error3" ? "" : "Error3")
          }
        />
        <Accordion
          children={"Failed to create 200 orders on Shopify."}
          title="Failed to create 200 orders on Shopify."
          icon={<AlertCircle size={20} color="var(--inte-Y300)" />}
          isActive={activeAccordian === "create3"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "create3" ? "" : "create3")
          }
        />
      </>
    ),
    announcement: (
      <>
        <Accordion
          children={"Error found in 20 products post product upload."}
          title="Error found in 20 products post product upload."
          icon={<Bell color="var(--inte-P900)" size={20} />}
          isActive={activeAccordian === "Error4"}
          onClick={() =>
            setActiveAccordian(activeAccordian === "Error4" ? "" : "Error4")
          }
        />
      </>
    ),
  };

  return (
    <Card title="Your Attention Required" cardType="bordered">
      <Tabs
        spacing="loose"
        tabs={tabs2}
        onChange={(newTabkey) => setSelectedTab(newTabkey)}
        value={selectedTab}
        children={tabContent[selectedTab]}
      />
    </Card>
  );
};

export default AttentionRequired;
