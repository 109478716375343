import {
  Seprator,
  TextField,
  Text,
  Button,
  Alert,
} from "@cedcommerce-integration/ounce";
import {
  Eye,
  EyeOff,
  CheckCircle,
  ArrowRight,
} from "@cedcommerce-integration/ounce/dist/icons";
import React, { FC, useState } from "react";
import "./Common.css";
import { useNavigate } from "react-router-dom";
import { checkIcon } from "./assets/icons/icons";
import LoginRegister from "./LoginRegister/LoginRegister";
export interface registerI {
  onClick?: (value: any) => void;
}
const ResetPassword: FC<registerI> = ({ onClick = () => {} }: registerI) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  const [textFields, setTextFields] = useState({
    field1: "",
    field2: "",
  });

  const handleOnchange = (fieldName: string, value: any) => {
    setTextFields((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <LoginRegister>
      {" "}
      <>
        <Text
          customClass="inte-auth_userHeading"
          fontweight="bold"
          type="T-4"
          as="h2"
        >
          Reset Password
        </Text>
        <Seprator customClass="inte-auth_headerDevider" />

        <div className="inte-auth__wrapper">
          {showAlert ? (
            <>
              <Alert
                type="info"
                icon={<CheckCircle size={20} />}
                title="You’re all set!"
                description="You are resetting password for emailid@gmail.com"
                hasDestroy
              />
              <TextField
                type={showPassword ? "text" : "password"}
                placeHolder="Enter New Password"
                value={textFields.field1}
                onChange={(e) => handleOnchange("field1", e)}
                hasStrength
                suffix={
                  showPassword ? (
                    <EyeOff
                      size={18}
                      onClick={() => setShowPassword(false)}
                      color="#1C2433"
                    />
                  ) : (
                    <Eye
                      size={18}
                      onClick={() => setShowPassword(true)}
                      color="#1C2433"
                    />
                  )
                }
              />
              <ul className="inte-auth__condation">
                <li>
                  To create a strong password make sure the password contains:
                </li>
                <ul className="inte-auth__condation--wrapper">
                  <li> {checkIcon}</li>
                  <li>A minimum of 8 characters</li>
                </ul>
                <ul className="inte-auth__condation--wrapper">
                  <li> {checkIcon}</li>
                  <li>An uppercase and a lowercase letter</li>
                </ul>
                <ul className="inte-auth__condation--wrapper">
                  <li> {checkIcon}</li>
                  <li>A number</li>
                </ul>
                <ul className="inte-auth__condation--wrapper">
                  <li> {checkIcon}</li>
                  <li>One special character</li>
                </ul>
              </ul>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                placeHolder="Confirm New Password"
                value={textFields.field2}
                onChange={(e) => handleOnchange("field2", e)}
                hasStrength
                suffix={
                  showConfirmPassword ? (
                    <EyeOff
                      size={18}
                      onClick={() => setShowConfirmPassword(false)}
                      color="#1C2433"
                    />
                  ) : (
                    <Eye
                      size={18}
                      onClick={() => setShowConfirmPassword(true)}
                      color="#1C2433"
                    />
                  )
                }
              />{" "}
            </>
          ) : (
            <Alert
              type="success"
              icon={<CheckCircle />}
              title="Password Reset Successful!"
              hasDestroy
            />
          )}
        </div>

        <Seprator customClass="inte-auth__bottomSeprator" />
        <Button
          customClass="inte-register__registerButton"
          halign="center"
          icon={!showAlert && <ArrowRight size="20" />}
          content={showAlert ? "Save" : "Login"}
          isFullWidth
          onClick={() => {
            setShowAlert(false);
            !showAlert && navigate("/");
          }}
        />
      </>
    </LoginRegister>
  );
};

export default ResetPassword;
