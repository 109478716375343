import React, { useEffect, useState } from "react";
import {
  Accordion,
  AnimatingIllustration,
  Card,
  FallBack,
  FlexLayout,
  PageHeader,
  Seprator,
  Tabs,
  Text,
  TextField,
  TextLink,
} from "@cedcommerce-integration/ounce";
import { Search } from "@cedcommerce-integration/ounce/dist/icons";
import {
  Token,
  NoSearchResultFound,
  Email,
} from "@cedcommerce-integration/ounce/dist/illustrations";
import { useNavigate } from "react-router-dom";
import "./Help.css";

const Help = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Amazon");
  const [clicked, setClicked] = useState<number>(-1);

  const tabsData = [
    {
      key: "Amazon",
      label: "Amazon",
    },

    {
      key: "Ebay",
      label: "Ebay",
    },
    {
      key: "Walmart",
      label: "Walmart",
    },
  ];
  const accordionArr = [
    {
      title: "Is there a free trial available?",
      answer:
        "Is there a free trial available? Is there a free trial available? ",
    },
    {
      title: "Is there a free trial available?",
      answer:
        "Is there a free trial available? Is there a free trial available? ",
    },
    {
      title: "Is there a free trial available?",
      answer:
        "Is there a free trial available? Is there a free trial available? ",
    },
  ];

  const handleToggle = (index: number) => {
    if (clicked === index) {
      setClicked(-1);
    } else {
      setClicked(index);
    }
  };
  const renderTabsData: { [key: string]: JSX.Element } = {
    Amazon: (
      <Card cardType="bordered">
        {accordionArr.map((items: any, index: number) => {
          return (
            <Accordion
              title={items.title}
              key={index}
              isActive={clicked === index}
              onClick={() => handleToggle(index)}
            >
              <Text textcolor="secondary">{items.answer}</Text>
            </Accordion>
          );
        })}
      </Card>
    ),
    Ebay: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No FAQ Found"
          subTitle="FAQ's will be shown once they are added"
          illustration={<AnimatingIllustration animationData="Journal" loop />}
        />
      </FlexLayout>
    ),
    Walmart: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No FAQ Found"
          subTitle="FAQ's will be shown once they are added"
          illustration={<AnimatingIllustration animationData="Journal" loop />}
        />
      </FlexLayout>
    ),
  };
  const cardData = [
    {
      title: "Schedule Meeting",
      description: "View most frequently questions asked queries by merchants.",
      icon: <NoSearchResultFound />,
    },
    {
      title: "Mail Us at",
      description: "View most frequently questions asked queries by merchants",
      icon: <Email />,
    },
    {
      title: "Raise a Ticket",
      description: "View most frequently questions asked queries by merchants",
      icon: <Token />,
    },
  ];

  useEffect(() => {
    let holdData: any = [];
    fetch("https://dummyjson.com/products?&limit=100")
      .then((res) => res.json())
      .then((res) => {
        res?.products?.map((item: any, index: number) => {
          return (holdData = [
            ...holdData,
            {
              label: item.title,
              value: item.description,
            },
          ]);
        });
      });
  }, []);
  return (
    <>
      <PageHeader
        title="Help"
        description="Get instant help from CedCommerce’s team of experts. Solve any problem you encounter."
        customClass="inte-custom__pageHeader"
      />
      <FlexLayout direction="vertical" spacing="extraLoose">
        <Text type="T-7" fontweight="bold">
          Reach out to us
        </Text>
        <FlexLayout
          desktopWidth="33"
          tabWidth="33"
          mobileWidth="100"
          spacing="tight"
        >
          {cardData.map((item: any, index: number) => {
            return (
              <Card
                cardType="bordered"
                onClick={() => alert(item.title)}
                key={index}
              >
                <FlexLayout spacing="extraLoose" wrap="noWrap">
                  <div className="inte-illustration">{item.icon}</div>
                  <FlexLayout direction="vertical" spacing="mediumTight">
                    <Text type="T-7" fontweight="bold" textcolor="primary">
                      {item.title}
                    </Text>
                    <Text type="T-7" fontweight="normal" textcolor="secondary">
                      {item.description}
                    </Text>
                  </FlexLayout>
                </FlexLayout>
              </Card>
            );
          })}
        </FlexLayout>
        <FlexLayout direction="vertical" spacing="mediumLoose">
          <Seprator />
          <FlexLayout halign="fill">
            <Text type="T-7" fontweight="bold">
              FAQs
            </Text>
            <TextLink
              label="  View all FAQs"
              onClick={() => navigate("/faq")}
            />
          </FlexLayout>

          <TextField placeHolder="Search faq" prefix={<Search size={20} />} />

          <Tabs
            direction="horizontal"
            tabs={tabsData}
            value={selectedTab}
            onChange={(newTab: any) => setSelectedTab(newTab)}
            customClass="inte-custom__tabs"
          >
            {renderTabsData[selectedTab as string]}
          </Tabs>
        </FlexLayout>
      </FlexLayout>
    </>
  );
};

export default Help;
