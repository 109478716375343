import {
  Accordion,
  Badge,
  Card,
  FlexChild,
  FlexLayout,
  Select,
  Seprator,
  Text,
  useMobileDevice,
} from "@cedcommerce-integration/ounce";
import { Check } from "@cedcommerce-integration/ounce/dist/icons";
import React, { useState } from "react";

const AttributeMapping = () => {
  const [activeAccordian, setActiveAccordian] = useState<number>(-1);
  const isMobile = useMobileDevice();

  const attributesOptions = [
    {
      label:"Attribute1",
      value:"attribute1"
    },
    {
      label:"Attribute2",
      value:"attribute2"
    },
    {
      label:"Attribute3",
      value:"attribute3"
    }
  ]

  const accordianChildren = !isMobile ? (
    <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
      <FlexLayout spacing="tight" childWidth="fullWidth">
        <FlexChild desktopWidth="50">
          <Text type="T-7" fontweight="bold">
            TikTok Shop Attribute
          </Text>
        </FlexChild>
        <FlexChild desktopWidth="50">
          <Text type="T-7" fontweight="bold">
            BigCommerce Attribute
          </Text>
        </FlexChild>
      </FlexLayout>
      <Text type="T-7">Subheading content</Text>
      <FlexLayout spacing="tight" childWidth="fullWidth">
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexChild>
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={attributesOptions}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
      <Seprator />
      <FlexLayout spacing="tight" childWidth="fullWidth">
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexChild>
        <FlexChild desktopWidth="50">
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={attributesOptions}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
    </FlexLayout>
  ) : (
    <FlexLayout direction="vertical" wrap="noWrap" spacing="mediumLoose">
      <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            TikTok Shop Attribute
          </Text>
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexLayout>
        <Seprator type="dashed" />
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            BigCommerce Attribute
          </Text>
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={attributesOptions}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <Seprator />
      <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            TikTok Shop Attribute
          </Text>
          <FlexLayout spacing="tight">
            <Badge icon={<Check size={16} />} size="small" type="success" />
            <Text type="T-7">
              Package Length{" "}
              <Text as="span" textcolor="negative">
                *
              </Text>
            </Text>
          </FlexLayout>
        </FlexLayout>
        <Seprator type="dashed" />
        <FlexLayout spacing="mediumTight" direction="vertical" wrap="noWrap">
          <Text type="T-7" fontweight="bold">
            BigCommerce Attribute
          </Text>
          <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
            <Select
              options={attributesOptions}
              value={""}
              placeholder="Select Attribute"
            />
            <Select
              options={[{ label: "length", value: "se" }]}
              value={"se"}
              placeholder="Select Attribute"
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );

  return (
    <Card
      customClass="required-card"
      cardType="bordered"
      title="Select Attribute Mapping"
      subTitle="Through attribute mapping you can enhance your listing catalog with additional listing information."
    >
      <Accordion
        title="Accordion Header Accordion Header Accordion Header"
        onClick={() => setActiveAccordian((prev) => (prev === 0 ? -1 : 0))}
        isActive={activeAccordian === 0}
        children={accordianChildren}
      />
      <Accordion
        title="Accordion Header Accordion Header Accordion Header"
        onClick={() => setActiveAccordian((prev) => (prev === 1 ? -1 : 1))}
        isActive={activeAccordian === 1}
        children={accordianChildren}
      />
      <Accordion
        customClass="without-border-bottom-accordian"
        title="Accordion Header Accordion Header Accordion Header"
        onClick={() => setActiveAccordian((prev) => (prev === 2 ? -1 : 2))}
        isActive={activeAccordian === 2}
        children={accordianChildren}
      />
    </Card>
  );
};

export default AttributeMapping;
