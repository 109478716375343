import {
  Badge,
  Button,
  Card,
  Carousel,
  FlexLayout,
  Seprator,
  Text,
} from "@cedcommerce-integration/ounce";

import { Check } from "@cedcommerce-integration/ounce/dist/icons";
import ToggleButton from "./Toggle";
import { useState } from "react";

import React from "react";


function SlideAdded() {
  const [toggle, setToggle] = useState("Monthly");
  const price = [
    {
      type: "Premium",
      price: "$49",
      children: [
        "Manage upto 500 orders per month.",
        "Upload Product",
        "Inventory Syncing",
        "Out-of-stock Control",
        " Manage Order Cancellation Refunds",
      ],
    },
    {
      type: "Advance",
      price: "$99",
      children: [
        "Manage upto 1000 orders per month.",
        "Upload Product",
        "Inventory Syncing",
        "Out-of-stock Control",
        " Manage Order Cancellation Refunds",
      ],
    },
    {
      type: "Plantinum",
      price: "$149",
      children: [
        "Manage upto 1500 orders per month.",
        "Upload Product",
        "Inventory Syncing",
        "Out-of-stock Control",
        " Manage Order Cancellation Refunds",
      ],
    },
    {
      type: "Professional",
      price: "$199",
      children: [
        "Manage upto 5000 orders per month.",
        "Upload Product",
        "Inventory Syncing",
        "Out-of-stock Control",
        " Manage Order Cancellation Refunds",
      ],
    },
  ];
  return (
    <FlexLayout
      direction="vertical"
      desktopWidth="100"
      mobileWidth="100"
      spacing="mediumLoose"
    >
      <FlexLayout halign="center">
        <ToggleButton
          toggle={toggle}
          onMonthly={() => setToggle("Monthly")}
          onYearly={() => setToggle("Yearly")}
        />
      </FlexLayout>
      <Carousel
      customClass="custom-button_center"
      showArrows={true}
        isEndless
        showDots
        slideUsingMouse
        slideUsingTouch
        slidesToShow={3}
        spaceBetweenSlides={20}
        breakpoints={{
          "480": {
            showDots: true,
            slidesToShow: 1,
          },
          "600": {
            showDots: true,
            slidesToShow: 2,
          },
          "1024": {
            showDots: true,
            slidesToShow: 3,
          },
        }}
      >
        {price.map((items: any, index: number) => {
          return (
          <React.Fragment key={index}>
            <Card key={index} cardType="filled" spacing="loose">
              <FlexLayout
                direction="vertical"
                spacing="tight"
              >
                <FlexLayout  direction="vertical" valignMob="center" >
                 <FlexLayout halign="fill" spacing="mediumLoose">
                 <Text type="T-6" fontweight="bold">
                    {items.type}
                  </Text>
                  {items.type==="Advance"&&<div className="Custom-badge">Most Popular</div>}
                 </FlexLayout>

                  <FlexLayout valign="baseline">
                    <Text type="T-1" textcolor="primary" fontweight="bolder">
                      {items.price}
                    </Text>
                    <Text>/{toggle === "Monthly" ? "Month" : "Year"}</Text>
                  </FlexLayout>
                  <FlexLayout>
                    <Text type="T-7">
                    Billed {toggle ==="Monthly" ? "Monthly" : "Yearly"}
                    </Text>
                  </FlexLayout>
                </FlexLayout>
                <Seprator />
                <Text type="T-7">Plan Includes:</Text>
                <FlexLayout direction="vertical" spacing="tight">
                  {price[index].children.map((items,index) => {
                    return (
                      <FlexLayout key={index} spacing="mediumTight" wrap="noWrap" >
                        <Badge icon={<Check size={16}/>} variant="accent" />
                        <Text type="T-7" textcolor="secondary">
                          {items}
                        </Text>
                      </FlexLayout>
                    );
                  })}
                </FlexLayout>
               {items.type==="Advance"? <Button
               type="secondary"
               icon={<Check size={20}/>}
                  content="Active Plan"
                  isFullWidth
                  halign="center"
                />: <Button
                content="Subscribe"
                isFullWidth
                halign="center"
              />}
              </FlexLayout>
            </Card>
          </React.Fragment>
          );
        })}
      </Carousel>
    </FlexLayout>
  );
}
export default SlideAdded;
