import React, { useEffect, useMemo, useState } from "react";
import {
  ActionList,
  AnimatingIllustration,
  Badge,
  Button,
  ChoiceList,
  DataTable,
  FallBack,
  Filter,
  FlexChild,
  FlexLayout,
  PageHeader,
  Pagination,
  RadioGroup,
  RangeSlider,
  Tabs,
  Text,
  TextField,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import { useNavigate } from "react-router-dom";
import {
  ChevronDown,
  ChevronUp,
  FilterIcon,
  MoreVertical,
  Search,
  Theme,
} from "@cedcommerce-integration/ounce/dist/icons";
import "./Order.css";

const OrderGrid = () => {
  const { width } = useWindowResize();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [grpVal, setGrpVal] = useState<string | number>("Active");
  const [selectedTab, setSelectedTab] = useState("Failed");
  const [buttonAction, setButtonAction] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [choiceValue, setChoiceValue] = useState(["Order ID"]);
  const [priceRange, setPriceRange] = useState<[number, number]>([25, 70]);
  const [tableDataRow, setTableRowData] = useState([
    {
      id: 1,
      key: "1",
      name: "",
    },
  ]);

  const holdData: any = [];
  useEffect(() => {
    let holdAutoData: any = [];
    setDataLoading(true);
    fetch("https://dummyjson.com/products?&limit=100")
      .then((res) => res.json())
      .then((json) => {
        setDataLoading(false);
        json.products.map((items: any) => {
          return (
            <>
              {holdData.push({
                id: items.id,
                key: `${items.id}`,
                orderID: <Text type="T-7">3456745666</Text>,
                customeName: <Text type="T-7">S***** U*****</Text>,
                orderDate: <Text type="T-7">2023-07-05</Text>,
                price: (
                  <FlexLayout direction="vertical">
                    <Text textcolor="success" fontweight="bold" type="T-7">
                      ${items.price}
                    </Text>

                    <Text>USD</Text>
                  </FlexLayout>
                ),
                status: (
                  <Badge type="error" variant="accent">
                    Failed
                  </Badge>
                ),
                shippingDetails: <Text type="T-7">Label</Text>,
                template: <Text type="T-7">Template</Text>,
              })}
              {
                (holdAutoData = [
                  ...holdAutoData,
                  {
                    label: items.title,
                    value: items.description,
                  },
                ])
              }
            </>
          );
        });

        setTableRowData(holdData);
      });
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * countPerPage;
    const lastPageIndex = firstPageIndex + countPerPage;
    return tableDataRow.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, countPerPage, tableDataRow]);

  const filterButton =
    width > 768 ? (
      <Button type="outlined" onClick={() => setIsOpenFilter(!isOpenFilter)}>
        More Filters
      </Button>
    ) : (
      <Button
        type="outlined"
        onClick={() => setIsOpenFilter(!isOpenFilter)}
        icon={<FilterIcon size={20} />}
      />
    );

  const ShowActions = (item: any) => {
    const [showAction, setShowAction] = useState(false);
    const [holdId, setHoldId] = useState(-1);
    return (
      <ActionList
        isOpen={showAction}
        heading="Customize Grid"
        onClose={() => setShowAction(false)}
        activator={
          <Button
            onClick={() => {
              setHoldId(item?.item?.id);
              setShowAction(!showAction);
            }}
            icon={<MoreVertical size="20" />}
            type="outlined"
          />
        }
        options={[
          {
            items: [
              {
                content: "View Order",
                onClick: () =>
                  navigate("/orders/orderlist/vieworder", {
                    state: holdId,
                  }),
              },
            ],
          },
        ]}
      />
    );
  };
  const columnData = [
    {
      dataIndex: "orderID",
      key: "orderID",
      title: "Order ID",
    },
    {
      dataIndex: "customeName",
      key: "customeName",
      title: "Custome Name",
    },
    {
      dataIndex: "orderDate",
      key: "orderDate",
      title: "Order Date",
    },
    {
      dataIndex: "price",
      key: "price",
      title: "Price",
      width: 100,
    },
    {
      dataIndex: "status",
      key: "status",
      title: "Status",
      width: 100,
    },

    {
      dataIndex: "shippingDetails",
      key: "shippingDetails",
      title: "Shipping Details",
    },

    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      render: (item: any) => <ShowActions item={item} />,
    },
  ];

  const tabsData = [
    {
      key: "Failed",
      label: "Failed",
      badge: (
        <Badge size="small" type="error" variant="accent">
          100
        </Badge>
      ),
    },
    {
      key: "Cancelled",
      label: "Cancelled",
      badge: (
        <Badge size="small" type="error" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "Unfulfilled",
      label: "Unfulfilled",
      badge: (
        <Badge size="small" type="warning" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "Fulfilled",
      label: "Fulfilled",
      badge: (
        <Badge size="small" type="success" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "InTransit",
      label: "In Transit",
      badge: (
        <Badge size="small" type="primary" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "Others",
      label: "Others",
      badge: (
        <Badge size="small" type="warning" variant="accent">
          0
        </Badge>
      ),
    },
  ];

  const checkActionBtn = (
    <Button
      onClick={() => setButtonAction(!buttonAction)}
      customClass="inte-button__custom"
      icon={
        <FlexLayout spacing="mediumTight" wrap="noWrap">
          {width < 768 && <Theme size="20" color="#1c2433" />}
          {buttonAction ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
        </FlexLayout>
      }
      type="outlined"
      iconAlign="right"
      content={`${width > 768 ? "Customize Grid" : ""}`}
    />
  );

  const checkAction = (
    <ChoiceList
      activator={checkActionBtn}
      heading="ChoiceList Heading"
      onClose={() => setButtonAction(false)}
      isCloseOnEsc
      isMulti
      onChange={(val: any) => setChoiceValue(val)}
      value={choiceValue}
      isOpen={buttonAction}
      options={[
        {
          label: "Order ID",
          value: "Order ID",
        },
        {
          label: "Customer Name",
          value: "Customer Name",
        },
        {
          label: "Price",
          value: "Price",
        },
        {
          label: "Status",
          value: "Status",
        },
        {
          label: "Shipping Details",
          value: "Shipping Details",
        },
        {
          label: "Actions",
          value: "Actions",
        },
      ]}
    />
  );

  const renderTabsData: { [key: string]: JSX.Element } = {
    Failed: (
      <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="tight">
          <FlexChild childWidth="fullWidth">
            <TextField placeHolder="Search by order id" prefix={<Search />} />
          </FlexChild>

          <FlexLayout spacing="tight">
            {width > 768 && checkAction}

            <Filter
              activator={filterButton}
              filters={[
                {
                  key: "price",
                  label: "Price",
                  children: (
                    <RangeSlider
                      isRange
                      min={0}
                      max={100}
                      label="Min and Max Value"
                      value={priceRange}
                      onChange={(value) => setPriceRange(value)}
                      toolTip
                    />
                  ),
                },
                {
                  key: "Status",
                  label: "Status",
                  children: (
                    <RadioGroup
                      direction="vertical"
                      onChange={(e) => setGrpVal(e)}
                      options={[
                        {
                          label: "Active",
                          value: "Active",
                        },
                        {
                          label: "Inactive",
                          value: "Inactive",
                        },
                        {
                          label: "Error",
                          value: "Error",
                        },

                        {
                          label: "Warning",
                          value: "Warning",
                        },
                        {
                          label: "Paused",
                          value: "Paused",
                        },
                        {
                          label: "Failed",
                          value: "Failed",
                        },
                      ]}
                      value={grpVal}
                    />
                  ),
                },
                {
                  key: "customerName",
                  label: "Customer Name",
                  children: <TextField placeHolder="Enter customer Name" />,
                },
              ]}
              heading="Filters"
              isCloseOnEsc
              onClose={() => setIsOpenFilter(false)}
              primaryAction={{
                content: "Apply",
                halign: "center",
                isFullWidth: true,
                onClick: () => setIsOpenFilter(false),
              }}
              secondaryAction={{
                content: "Clear All",
                halign: "center",
                onClick: () => setIsOpenFilter(false),
                type: "outlined",
              }}
              isOpen={isOpenFilter}
            />

            {width < 768 && checkAction}
          </FlexLayout>
        </FlexLayout>

        <DataTable
          dataSource={currentTableData}
          rowSelection={{}}
          expandable={{
            expandedRowRender: (item: any) => (
              <DataTable dataSource={currentTableData} columns={columnData} />
            ),
          }}
          isLoading={dataLoading}
          pagination={
            <Pagination
              currentPage={currentPage}
              countPerPage={countPerPage}
              totalitem={tableDataRow.length}
              onPageChange={(e) => setCurrentPage(e)}
              onEnter={(e) => setCurrentPage(e)}
              onPrevious={() => setCurrentPage(currentPage - 1)}
              onNext={() => setCurrentPage(currentPage + 1)}
              onCountChange={(count) => {
                setCountPerPage(count);
                setCurrentPage(1);
              }}
              optionPerPage={[
                { label: "10", value: "10" },
                { label: "15", value: "15" },
                { label: "20", value: "20" },
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "60", value: "60" },
                { label: "70", value: "70" },
                { label: "80", value: "80" },
                { label: "90", value: "90" },
                { label: "100", value: "100" },
              ]}
            />
          }
          columns={columnData}
        />
      </FlexLayout>
    ),
    Cancelled: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No orders Found"
          illustration={
            <AnimatingIllustration animationData="NoOrderFound" loop />
          }
        />
      </FlexLayout>
    ),
    Unfulfilled: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No orders Found"
          illustration={
            <AnimatingIllustration animationData="NoOrderFound" loop />
          }
        />
      </FlexLayout>
    ),
    Fulfilled: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No orders Found"
          illustration={
            <AnimatingIllustration animationData="NoOrderFound" loop />
          }
        />
      </FlexLayout>
    ),
    InTransit: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No orders Found"
          illustration={
            <AnimatingIllustration animationData="NoOrderFound" loop />
          }
        />
      </FlexLayout>
    ),
    Others: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No orders Found"
          illustration={
            <AnimatingIllustration animationData="NoOrderFound" loop />
          }
        />
      </FlexLayout>
    ),
  };

  return (
    <>
      <PageHeader
        title="Orders List"
        description="Here’s what’s happening in your store today."
        customClass="inte-custom__pageHeader"
      />
      <Tabs
        direction="horizontal"
        tabs={tabsData}
        value={selectedTab}
        onChange={(newTab: any) => setSelectedTab(newTab)}
        customClass="inte-custom__tabs"
      >
        {renderTabsData[selectedTab as string]}
      </Tabs>
    </>
  );
};

export default OrderGrid;
