import product from '../../../assests/images/product.png'
import {Card, FlexLayout, Text, TextLink, Thumbnail } from '@cedcommerce-integration/ounce'

const TopSellingPro = () => {
  return (
    <Card
      title="Top Selling Products"
      cardType='bordered'
    >
           
      <FlexLayout spacing='loose' direction='vertical'>
        <FlexLayout spacing='tight' wrap='noWrap'>
          <Thumbnail size='large' alt='img' src={product}/>
          <FlexLayout spacing='extraTight' direction='vertical'>
            <Text truncate={1}>1ZPRESSO | J-MAX Manual Coffee Grinder</Text>
            <Text>Listing ID : <TextLink label="12345678" /></Text>
            <Text textcolor='secondary'>Ordered 200 times in the last month</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout spacing='tight'  wrap='noWrap'>
          <Thumbnail size='large' alt='img' src={product}/>
          <FlexLayout spacing='extraTight' direction='vertical'>
            <Text truncate={1}>1ZPRESSO | J-MAX Manual Coffee Grinder</Text>
            <Text>Listing ID : <TextLink label="12345678" /></Text>
            <Text textcolor='subdued'>Ordered 200 times in the last month</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout spacing='tight'  wrap='noWrap'>
          <Thumbnail size='large' alt='img' src={product}/>
          <FlexLayout spacing='extraTight' direction='vertical'>
            <Text truncate={1}>1ZPRESSO | J-MAX Manual Coffee Grinder</Text>
            <Text>Listing ID : <TextLink label="12345678" /></Text>
            <Text textcolor='subdued'>Ordered 200 times in the last month</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout spacing='tight' wrap='noWrap'>
          <Thumbnail size='large' alt='img' src={product}/>
          <FlexLayout spacing='extraTight' direction='vertical'>
            <Text truncate={1}>1ZPRESSO | J-MAX Manual Coffee Grinder</Text>
            <Text>Listing ID : <TextLink label="12345678" /></Text>
            <Text textcolor='subdued'>Ordered 200 times in the last month</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout spacing='tight' wrap='noWrap'>
          <Thumbnail size='large' alt='img' src={product}/>
          <FlexLayout spacing='extraTight' direction='vertical'>
            <Text truncate={1}>1ZPRESSO | J-MAX Manual Coffee Grinder</Text>
            <Text>Listing ID : <TextLink label="12345678" /></Text>
            <Text textcolor='subdued'>Ordered 200 times in the last month</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout spacing='tight' wrap='noWrap'>
          <Thumbnail size='large' alt='img' src={product}/>
          <FlexLayout spacing='extraTight' direction='vertical'>
            <Text truncate={1}>1ZPRESSO | J-MAX Manual Coffee Grinder</Text>
            <Text>Listing ID : <TextLink label="12345678" /></Text>
            <Text textcolor='subdued'>Ordered 200 times in the last month</Text>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Card>
  )
}

export default TopSellingPro