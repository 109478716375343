import React, { useEffect, useMemo, useState } from "react";
import {
  ActionList,
  AnimatingIllustration,
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  DataTable,
  FallBack,
  Filter,
  FlexChild,
  FlexLayout,
  Modal,
  PageHeader,
  Pagination,
  Radio,
  RadioGroup,
  RangeSlider,
  Tabs,
  Text,
  TextField,
  TextLink,
  Thumbnail,
  useWindowResize,
} from "@cedcommerce-integration/ounce";
import {
  Search,
  MoreVertical,
  FilterIcon,
  Edit,
  Eye,
  X,
} from "@cedcommerce-integration/ounce/dist/icons";
import { useNavigate } from "react-router-dom";
import "./Product.css";

const ProductList = () => {
  const navigate = useNavigate();
  const { width } = useWindowResize();
  const [buttonAction, setButtonAction] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [grpVal, setGrpVal] = useState<string | number>("Active");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [typesP, setTypesP] = useState({ attr: true, text: "simple" });
  const [bulkAction, setBulkAction] = useState(false);
  const [confirDeleteModa, setConfirDeleteModa] = useState(false);
  const [quantityRange, setQuantityRange] = useState<[number, number]>([
    20, 60,
  ]);
  const [priceRange, setPriceRange] = useState<[number, number]>([25, 70]);
  const [tableDataRow, setTableRowData] = useState([
    {
      id: 1,
      key: "1",
      name: "",
    },
  ]);

  const [selectedRowKey, setSelectedRowKey] = useState<any>({});

  useEffect(() => {
    let holdAutoData: any = [];
    let holdData: any = [];
    setDataLoading(true);
    fetch("https://dummyjson.com/products?&limit=100")
      .then((res) => res.json())
      .then((json) => {
        setDataLoading(false);
        json.products.map((items: any) => {
          return (
            <React.Fragment key={items.id}>
              {
                (holdData = [
                  ...holdData,
                  {
                    id: items.id,
                    key: `${items.id}`,
                    image: <Thumbnail src={`${items.thumbnail}`} />,
                    title: items.title,
                    details: (
                      <FlexLayout direction="vertical">
                        <FlexLayout>
                          <Text fontweight="bold">ASIN:</Text>
                          <Text>B0775MV9K2</Text>
                        </FlexLayout>
                        <FlexLayout>
                          <Text fontweight="bold">SKU:</Text>
                          <Text>4772138942647</Text>
                        </FlexLayout>
                        <FlexLayout>
                          <Text fontweight="bold">Barcode:</Text>
                          <Text>X000RYXZW</Text>
                        </FlexLayout>
                      </FlexLayout>
                    ),
                    status: (
                      <Badge type="success" variant="accent">
                        Active
                      </Badge>
                    ),
                    price: (
                      <FlexLayout direction="vertical">
                        <Text textcolor="success" fontweight="bold" type="T-7">
                          ${items.price}
                        </Text>
                        <Text>USD</Text>
                      </FlexLayout>
                    ),

                    quantity: <Text type="T-7">1000</Text>,
                    activity: <Text type="T-7">No recent activities</Text>,
                    template: <Text type="T-7">Template</Text>,
                  },
                ])
              }

              {
                (holdAutoData = [
                  ...holdAutoData,
                  {
                    label: items.title,
                    value: items.description,
                  },
                ])
              }
            </React.Fragment>
          );
        });
        const selectedRowKeys: any = {};
        json.products.map((item: any) => {
          return (selectedRowKeys[item.id] = false);
        });
        setSelectedRowKey(selectedRowKeys);
        setTableRowData(holdData);
      });
  }, []);

  const currSelectedRowKeys = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * countPerPage;
    const lastPageIndex = firstPageIndex + countPerPage;
    const currData: any = {};
    Object.entries(selectedRowKey)
      .slice(firstPageIndex, lastPageIndex)
      .map((item: any) => {
        return (currData[item[0]] = item[1]);
      });
    return currData;
  }, [currentPage, countPerPage, selectedRowKey]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * countPerPage;
    const lastPageIndex = firstPageIndex + countPerPage;
    return tableDataRow.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, countPerPage, tableDataRow]);

  const filterButton =
    width > 768 ? (
      <Button type="outlined" onClick={() => setIsOpenFilter(!isOpenFilter)}>
        More Filters
      </Button>
    ) : (
      <Button
        type="outlined"
        onClick={() => setIsOpenFilter(!isOpenFilter)}
        icon={<FilterIcon size={20} />}
      />
    );

  const ShowActions = (items: any) => {
    const [showAction, setShowAction] = useState(false);
    const [imageLink, setImageLink] = useState("");
    const [holdId, setHoldId] = useState(-1);
    return (
      <ActionList
        isOpen={showAction}
        heading="Heading"
        onClose={() => setShowAction(false)}
        activator={
          <Button
            onClick={() => {
              setHoldId(items?.item?.id);
              setImageLink(items?.item?.image?.props?.src);
              setShowAction(!showAction);
            }}
            icon={<MoreVertical size="20" />}
            type="outlined"
          />
        }
        options={[
          {
            items: [
              {
                content: "Edit Product",
                prefixIcon: <Edit size="20" />,
                onClick: () =>
                  navigate("editProduct", {
                    state: holdId,
                  }),
              },
              {
                content: "View Product",
                prefixIcon: <Eye size="24" />,
                onClick: () =>
                  navigate("/product/productlist/viewProduct", {
                    state: { id: holdId, imgLink: imageLink },
                  }),
              },
            ],
          },
        ]}
      />
    );
  };
  const columnData = [
    {
      dataIndex: "image",
      key: "image",
      width: 80,
      title: "Image",
    },
    {
      dataIndex: "title",
      key: "title",
      title: "Title",
    },
    {
      dataIndex: "details",
      key: "details",
      title: "Details",
    },
    {
      dataIndex: "status",
      key: "status",
      title: "Status",
      width: 100,
    },
    {
      dataIndex: "price",
      key: "price",
      title: "Price",
      width: 100,
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: "Quantity",
      width: 100,
    },
    {
      dataIndex: "activity",
      key: "activity",
      title: "Activity",
    },
    {
      dataIndex: "template",
      key: "template",
      title: "Template",
    },
    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      render: (item: any) => <ShowActions item={item} />,
    },
  ];

  const tabsData = [
    {
      key: "All",
      label: "All",
      badge: (
        <Badge size="small" type="success" variant="accent">
          100
        </Badge>
      ),
    },
    {
      key: "Active",
      label: "Active",
      badge: (
        <Badge size="small" type="success" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "Inactive",
      label: "Inactive",
      badge: (
        <Badge size="small" type="secondary" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "Errors",
      label: "Errors",
      badge: (
        <Badge size="small" type="error" variant="accent">
          0
        </Badge>
      ),
    },
    {
      key: "Inprogress",
      label: "In progress",
      badge: (
        <Badge size="small" type="warning" variant="accent">
          0
        </Badge>
      ),
    },
  ];

  const handelSelectChange = (item: any) => {
    setSelectedRowKey((prev: any) => ({ ...prev, ...item }));
  };

  const trueCount = useMemo(() => {
    return Object.values(selectedRowKey).filter((value) => value === true)
      .length;
  }, [selectedRowKey]);

  useMemo(() => {}, []);
  const renderTabsData: { [key: string]: JSX.Element } = {
    All: (
      <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="tight">
          <FlexChild childWidth="fullWidth">
            <TextField
              placeHolder="Search for mobile , laptop"
              prefix={<Search size="20" />}
            />
          </FlexChild>

          <FlexLayout spacing="tight">
            {width > 768 && (
              <ButtonGroup segmented>
                <Button type="outlined">Sync Status</Button>
                <Button type="outlined">Amazon Lookup</Button>
              </ButtonGroup>
            )}

            <Filter
              activator={filterButton}
              filters={[
                {
                  key: "type",
                  label: "Type of Product",
                  children: (
                    <FlexLayout direction="vertical" spacing="loose">
                      <Radio
                        label="Simple"
                        onChange={(e) =>
                          setTypesP({ attr: true, text: "simple" })
                        }
                        checked={typesP.attr && typesP.text === "simple"}
                      />
                      <Radio
                        label="Variant"
                        onChange={(e) =>
                          setTypesP({ attr: true, text: "variant" })
                        }
                        checked={typesP.attr && typesP.text === "variant"}
                      />
                    </FlexLayout>
                  ),
                },

                {
                  key: "quantity",
                  label: "Quantity",
                  children: (
                    <RangeSlider
                      isRange
                      min={0}
                      max={100}
                      label="Min and Max Value"
                      value={quantityRange}
                      onChange={(value) => setQuantityRange(value)}
                      toolTip
                    />
                  ),
                },
                {
                  key: "price",
                  label: "Price",
                  children: (
                    <RangeSlider
                      isRange
                      min={0}
                      max={100}
                      label="Min and Max Value"
                      value={priceRange}
                      onChange={(value) => setPriceRange(value)}
                      toolTip
                    />
                  ),
                },
                {
                  key: "Status",
                  label: "Status",
                  children: (
                    <RadioGroup
                      direction="vertical"
                      onChange={(e) => setGrpVal(e)}
                      options={[
                        {
                          label: "Active",
                          value: "Active",
                        },
                        {
                          label: "Inactive",
                          value: "Inactive",
                        },
                        {
                          label: "Error",
                          value: "Error",
                        },

                        {
                          label: "Warning",
                          value: "Warning",
                        },
                        {
                          label: "Paused",
                          value: "Paused",
                        },
                        {
                          label: "Failed",
                          value: "Failed",
                        },
                      ]}
                      value={grpVal}
                    />
                  ),
                },
                {
                  key: "template",
                  label: "Template",
                  children: (
                    <TextField placeHolder="Template" label="Template" />
                  ),
                },
              ]}
              heading="Filters"
              isCloseOnEsc
              onClose={() => setIsOpenFilter(false)}
              primaryAction={{
                content: "Apply",
                halign: "center",
                isFullWidth: true,
                onClick: () => setIsOpenFilter(false),
              }}
              secondaryAction={{
                content: "Clear All",
                halign: "center",
                onClick: () => setIsOpenFilter(false),
                type: "outlined",
              }}
              isOpen={isOpenFilter}
            />

            {width < 768 && (
              <ActionList
                heading="Action Button Group"
                isOpen={buttonAction}
                onClose={() => setButtonAction(false)}
                activator={
                  <Button
                    onClick={() => setButtonAction(!buttonAction)}
                    icon={<MoreVertical size="20" />}
                    type="outlined"
                  />
                }
                options={[
                  {
                    items: [
                      {
                        content: "Sync Status",
                        onClick: () => {},
                      },
                      {
                        content: "Amazon Lookup",
                        onClick: () => {},
                      },
                    ],
                  },
                ]}
              />
            )}
          </FlexLayout>
        </FlexLayout>

        {trueCount > 0 && (
          <div className="inte-grid__selectButton">
            <div className="inte-selectButton">
              <div
                onClick={() => alert("msg")}
                className="inte-selectButton__wrapper"
              >
                <div className="inte-selectButton__text">
                  <Checkbox checked="indeterminate" />
                  <Text type="T-7" fontweight="bold">
                    {trueCount} Selected
                  </Text>
                </div>
                <div className="inte-selectButton__icon">
                  <X />
                </div>
              </div>
              <TextLink label="Select all 50+ products" />
            </div>
            {width > 768 && (
              <FlexLayout halign="fill">
                <ButtonGroup>
                  <Button
                    content="Delete"
                    type="dangerOutlined"
                    onClick={() => setConfirDeleteModa(!confirDeleteModa)}
                  />
                  <ActionList
                    isOpen={bulkAction}
                    activator={
                      <Button
                        onClick={() => setBulkAction(!bulkAction)}
                        content="Bulk Action"
                        type="outlined"
                        disclosure
                      />
                    }
                    options={[
                      {
                        items: [
                          {
                            content: "Edit Product",
                            onClick: function noRefCheck() {},
                          },
                          {
                            content: "Sync",
                            onClick: function noRefCheck() {},
                          },
                        ],
                      },
                    ]}
                    onClose={() => setBulkAction(false)}
                  />
                </ButtonGroup>
              </FlexLayout>
            )}
          </div>
        )}

        <DataTable
          dataSource={currentTableData}
          rowSelection={{
            multi: true,
            selectedRowKeys: currSelectedRowKeys,
            onSelectChange: handelSelectChange,
          }}
          expandable={{
            expandedRowRender: (item: any) => (
              <DataTable dataSource={currentTableData} columns={columnData} />
            ),
          }}
          isLoading={dataLoading}
          pagination={
            <Pagination
              currentPage={currentPage}
              countPerPage={countPerPage}
              totalitem={tableDataRow.length}
              onPageChange={(e) => setCurrentPage(e)}
              onEnter={(e) => setCurrentPage(e)}
              onPrevious={() => setCurrentPage(currentPage - 1)}
              onNext={() => setCurrentPage(currentPage + 1)}
              onCountChange={(count) => {
                setCountPerPage(count);
                setCurrentPage(1);
              }}
              optionPerPage={[
                { label: "10", value: "10" },
                { label: "15", value: "15" },
                { label: "20", value: "20" },
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "60", value: "60" },
                { label: "70", value: "70" },
                { label: "80", value: "80" },
                { label: "90", value: "90" },
                { label: "100", value: "100" },
              ]}
            />
          }
          columns={columnData}
        />
      </FlexLayout>
    ),
    Active: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No product Found under this category"
          subTitle="You can check your filters or add products under this category"
          action={<Button type="primary">Import Products</Button>}
          illustration={
            <AnimatingIllustration
              style={{ height: "200px", width: "200px" }}
              animationData="NoProductAvailabel2"
            ></AnimatingIllustration>
          }
        />
      </FlexLayout>
    ),
    Inactive: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No product Found under this category"
          subTitle="You can check your filters or add products under this category"
          action={<Button type="primary">Import Products</Button>}
          illustration={
            <AnimatingIllustration
              style={{ height: "200px", width: "200px" }}
              animationData="NoProductAvailabel2"
            ></AnimatingIllustration>
          }
        />
      </FlexLayout>
    ),
    Errors: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No product Found under this category"
          subTitle="You can check your filters or add products under this category"
          action={<Button type="primary">Import Products</Button>}
          illustration={
            <AnimatingIllustration
              style={{ height: "200px", width: "200px" }}
              animationData="NoProductAvailabel2"
            ></AnimatingIllustration>
          }
        ></FallBack>
      </FlexLayout>
    ),

    Inprogress: (
      <FlexLayout spacing="loose" direction="vertical">
        <FallBack
          title="No product Found under this category"
          subTitle="You can check your filters or add products under this category"
          action={<Button type="primary">Import Products</Button>}
          illustration={
            <AnimatingIllustration
              style={{ height: "200px", width: "200px" }}
              animationData="NoProductAvailabel2"
            ></AnimatingIllustration>
          }
        ></FallBack>
      </FlexLayout>
    ),
  };
  return (
    <>
      <PageHeader
        title="Products List"
        description="Here’s what’s happening in your store today."
        customClass="inte-custom__pageHeader"
        secondaryAction={[
          {
            content: "Upload",
            onClick: () => {},
          },
        ]}
        primaryAction={{
          content: `${
            trueCount > 0 && width < 768 ? "Bulk Action" : "Sync Products"
          }`,
          type: `${trueCount > 0 && width < 768 ? "outlined" : "primary"}`,
          onClick: () => {},
        }}
      />

      <Tabs
        direction="horizontal"
        tabs={tabsData}
        value={selectedTab}
        onChange={(newTab: any) => setSelectedTab(newTab)}
        customClass="inte-custom__tabs"
      >
        {renderTabsData[selectedTab as string]}
      </Tabs>

      <Modal
        heading="Delete Product"
        isCloseOnEsc
        isOverlayClose
        modalSize="medium"
        onClose={() => setConfirDeleteModa(false)}
        primaryAction={{
          content: "Delete",
          type: "dangerOutlined",
          onClick: function noRefCheck() {},
        }}
        secondaryAction={{
          content: "Close",
          type: "secondary",
          onClick: () => setConfirDeleteModa(false),
        }}
        isOpen={confirDeleteModa}
      >
        Are you sure you want to delete this product?
      </Modal>
    </>
  );
};

export default ProductList;
