import { Card, FlexLayout, Notification, Progressbar, Seprator, Sheet, Text, TextLink,} from '@cedcommerce-integration/ounce'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import Activities from '../../../Notification/Activites'

const RecentActivity = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <Card
      cardType="bordered"
      spacing="tight"
      title="Recent Activities"
      action={<Sheet
        isOpen={isOpen}
        activator={<TextLink label="View All" onClick={() => setIsOpen(!isOpen)} />}
        heading="Sheet Witout Primary"
        onClose={() => setIsOpen(!isOpen)}
        secondaryAction={{
          content: 'View All Notifications',
          halign: 'center',
          onClick: () => navigate("/notification"),
          type: 'outlined',

        }}>
        <Activities />
      </Sheet>}
      customClass="header-card"
    >
      <FlexLayout direction="vertical" spacing="mediumLoose">
        <FlexLayout direction="vertical" spacing="mediumLoose">
          <FlexLayout direction="vertical" spacing="extraTight">
            <Text type='T-7'>Product Syncing in progress</Text>
            <Text type='T-7' textcolor="secondary">October 10, 2022, 10:03 GMT</Text>
          </FlexLayout>
          <Progressbar percentage={55} />
        </FlexLayout>
        <Seprator />
        <FlexLayout direction="vertical" spacing="mediumLoose">
          <Notification  title={"The product upload has been finished"} type="info" />
          <Seprator />
          <Notification title={"The product upload has been finished"} type="danger" />
          <Seprator />
          <Notification title={"The product upload has been finished"} type="success" />
        </FlexLayout>
      </FlexLayout>
    </Card>
  )
}

export default RecentActivity