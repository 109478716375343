import {
  Alert,
  Avatar,
  Button,
  Card,
  FlexLayout,
  Modal,
  Seprator,
  Text,
  TextField,
  useMobileDevice,
} from "@cedcommerce-integration/ounce";
import { Gitlab, Info, Copy } from "@cedcommerce-integration/ounce/dist/icons";
import React, { useState } from "react";

const Account = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMobileDevice();

  return (
    <Card>
      <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
        <Text as="h3" type="T-7" fontweight="bold">
          Source Account
        </Text>
        <Card cardType="bordered" spacing="tight">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="mediumTight">
                <Avatar text="Tick Tok" />
                <Text type="T-7" fontweight="bold">
                  TikTok Shop Account
                </Text>
              </FlexLayout>
              <Seprator />
            </FlexLayout>
            <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="mediumTight">
                <Text type="T-7">Seller Name:</Text>
                <FlexLayout wrap="noWrap">
                  <Text type="T-7" textcolor="secondary">TestCase UK28682</Text>
                  <Copy color="#1570EF" />
                </FlexLayout>
              </FlexLayout>
              <FlexLayout spacing="mediumTight">
                <Text type="T-7">Seller Id:</Text>
                <FlexLayout wrap="noWrap">
                  <Text type="T-7" textcolor="secondary">
                    2867852647864974846922
                  </Text>
                  <Copy color="#1570EF" />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </Card>
        {isMobile && <Seprator />}
        <Text as="h3" type="T-7" fontweight="bold">
          Target Account
        </Text>
        <Card cardType="bordered" spacing="tight">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="mediumTight" halign="fill">
                <Text type="T-7" fontweight="bold">
                  Shop Account 1
                </Text>
                <Button
                  type="dangerOutlined"
                  size="extraThin"
                  onClick={() => setModalOpen((prev) => !prev)}
                >
                  Disconnect
                </Button>
              </FlexLayout>
              <Seprator />
            </FlexLayout>
            <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="mediumTight">
                <Text type="T-7">Seller Name:</Text>
                <FlexLayout wrap="noWrap">
                  <Text type="T-7" textcolor="secondary">
                    TestCase UK28682
                  </Text>
                  <Copy color="#1570EF" />
                </FlexLayout>
              </FlexLayout>
              <FlexLayout spacing="mediumTight">
                <Text type="T-7">Seller Id:</Text>
                <FlexLayout wrap="noWrap">
                  <Text type="T-7" textcolor="secondary">
                    2867852647864974846922
                  </Text>
                  <Copy color="#1570EF" />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <Modal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            heading="Confirm Account Disconnection"
            headingIcon={<Gitlab size={24} />}
            secondaryAction={{
              content: "cancel",
              onClick: () => setModalOpen(false),
              type: "outlined",
            }}
            primaryAction={{ content: "Disconnect Account", type: "danger" }}
          >
            <FlexLayout spacing="tight" wrap="noWrap" direction="vertical">
              <Text type="T-7">
                The action will disconnect your account from our platform, and the
                syncing with your (App Name) will automatically be stopped. In
                order to keep the app functioning, make sure you keep your account
                connected.{" "}
              </Text>
              <Alert
                type="info"
                icon={<Info />}
                title="Note"
                description="All your information will be deleted from the app on account deletion. You can no longer access the app until you reconnect your Mirakl Connect and SFCC Account from scratch."
              />
              <TextField
                label={
                  <Text type="T-7">
                    Please type{" "}
                    <Text as={"span"} type="T-7" textcolor="negative">
                      Disconnect
                    </Text>{" "}
                    to proceed
                  </Text>
                }
              />
            </FlexLayout>
          </Modal>
        </Card>
      </FlexLayout>
    </Card>
  );
};

export default Account;
