import {
  ActionList,
  Button,
  Card,
  DataTable,
  FlexChild,
  FlexLayout,
  Modal,
  PageHeader,
  Pagination,
  Seprator,
  Text,
  TextField,
  TextLink,
} from "@cedcommerce-integration/ounce";
import {
  Edit,
  MoreVertical,
  Search,
  Trash2,
} from "@cedcommerce-integration/ounce/dist/icons";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const data = (n: number) => {
  return Array.from({ length: n }, (_, index) => ({
    name: "New Test",
    category:
      "Home > Computers > Laptops > ASUS Laptops > ASUS Chromebook Celeron Dual Core",
    totPro: 10,
    rules: "View Rules",
    key: index + 1,
  }));
};

const CategoryTemplateListing = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [totData, setTotData] = useState(data(100));

  const navigator = useNavigate();

  const currTableData = useMemo(() => {
    const firstItemIndex = (currentPage - 1) * itemPerPage;
    const lastItemIndex = firstItemIndex + itemPerPage;
    return totData.slice(firstItemIndex, lastItemIndex);
  }, [currentPage, itemPerPage, totData]);

  const onCountChange = (count: number) => {
    setItemPerPage(count);
    setCurrentPage(1);
  };

  const onNext = () => {
    if (typeof currentPage == "string") {
      setCurrentPage(parseInt(currentPage) + 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (typeof currentPage == "string") {
      setCurrentPage(parseInt(currentPage) - 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onEnter = (page: any) => {
    setCurrentPage(page);
  };
  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const ShowActions = ({ items }: any) => {
    const [showAction, setShowAction] = useState(false);
    return (
      <ActionList
        isOpen={showAction}
        heading="Heading"
        onClose={() => setShowAction(false)}
        activator={
          <Button
            onClick={() => setShowAction(!showAction)}
            icon={<MoreVertical size="20" />}
            type="outlined"
          />
        }
        options={[
          {
            items: [
              {
                content: "Edit",
                onClick: () => navigator("/category/create-category-template"),
                prefixIcon: <Edit size={20} />,
              },
              {
                content: "Delete",
                prefixIcon: <Trash2 size={20} color="var(--inte-R300)" />,
                onClick: () => {
                  setTotData((prev) => prev.filter((i) => i.key !== items.key));
                },
                destructive: true,
              },
            ],
          },
        ]}
      />
    );
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
      width: 500,
    },
    {
      key: "rules",
      title: "Rules",
      dataIndex: "rules",
      render: (item: string) => (
        <TextLink label={item} onClick={() => setOpenModal(true)} />
      ),
    },
    {
      key: "total-pro",
      title: "Total Products",
      dataIndex: "totPro",
    },
    {
      key: "actions",
      title: "Actions",
      render: (item: any) => <ShowActions items={item} />,
      fixed: "right",
    },
  ];

  return (
    <>
      <PageHeader
        title="Category Template Listing"
        description="Category Template are used for grouping similar products using querybuilder and assigned to specific categories and attributes while uploading on Amazon"
        connectRight={
          <FlexLayout wrap="noWrap" spacing="mediumTight">
            <Button size="extraThin" type="outlined">
              Edit Default Template
            </Button>
            <Button
              onClick={() => navigator("/category/create-category-template")}
              size="extraThin"
              type="primary"
            >
              Create Template
            </Button>
          </FlexLayout>
        }
      />
      <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
        <FlexLayout>
          <FlexChild desktopWidth="66" tabWidth="100" mobileWidth="100">
            <TextField
              onChange={() => {}}
              prefix={<Search size={20} />}
              placeHolder="Search Template"
            />
          </FlexChild>
        </FlexLayout>

        <DataTable
          columns={columns}
          dataSource={currTableData}
          pagination={
            <Pagination
              type="fullLength"
              currentPage={currentPage}
              totalitem={totData.length}
              onPageChange={onPageChange}
              onEnter={onEnter}
              onPrevious={onPrevious}
              onNext={onNext}
              onCountChange={onCountChange}
              countPerPage={itemPerPage}
            />
          }
        />
      </FlexLayout>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        heading="Product Rules"
      >
        <FlexLayout direction="vertical" spacing="tight">
          <Card customClass="background-card">
            <FlexLayout spacing="loose" childWidth="fullWidth" valign="center">
              <FlexChild desktopWidth="33">
                <Text type="T-7" alignment="left">
                  Category
                </Text>
              </FlexChild>
              <FlexChild desktopWidth="33">
                <Text type="T-7" alignment="center" fontweight="bold">
                  Equal
                </Text>
              </FlexChild>
              <FlexChild desktopWidth="33">
                <Text type="T-7" alignment="right">
                  Mobile
                </Text>
              </FlexChild>
            </FlexLayout>
          </Card>
          <Seprator text="or" />
          <Card customClass="background-card">
            <FlexLayout spacing="loose" childWidth="fullWidth" valign="center">
              <FlexChild desktopWidth="33">
                <Text type="T-7" alignment="left">
                  Name
                </Text>
              </FlexChild>
              <FlexChild desktopWidth="33">
                <Text type="T-7" alignment="center" fontweight="bold">
                  Contains
                </Text>
              </FlexChild>
              <FlexChild desktopWidth="33">
                <Text type="T-7" alignment="right">
                  Samsung
                </Text>
              </FlexChild>
            </FlexLayout>
          </Card>
        </FlexLayout>
      </Modal>
    </>
  );
};

export default CategoryTemplateListing;